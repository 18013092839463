.link {
  background: none;
  border: 0;
  color: var(--link-color, var(--color-text-primary));
  cursor: pointer;
  // display: inline-block;
  font: var(--text-link);
  margin: var(--link-margin);
  text-decoration: none;
  background-image: var(--link-border, var(--gradient-border));
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 100% 4px;
  @include u-baseline(1, padding-bottom);

  &:not([data-state='active']):hover {
    --link-border: linear-gradient(var(--color-polar), var(--color-polar));
  }

  &[data-state='active'] {
    --link-border: linear-gradient(currentColor, currentColor);
  }

  &[data-state='inactive'] {
    --link-border: linear-gradient(var(--color-polar), var(--color-polar));

    &:hover {
      --link-border: var(--gradient-border);
    }
  }

  &[data-size='large'] {
    font: var(--text-link-large);
  }

  &[data-type='full'] {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }
}

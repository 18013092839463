@use "sass:map";

.navbar {
  --navbar-background: var(--gradient-dark);
  --navbar-padding: #{rem-baseline(4)} #{rem-calc(map.get($global-config, 'layout-gutter'))} #{rem-baseline(2)};
  --navbar-item-color: var(--color-alabaster);
  --navbar-item-display: block;
  --navbar-item-margin: #{rem-baseline(4)} 0;
  background: var(--navbar-background);
  box-shadow: var(--shadow-high-centered);
  display: none;
  visibility: hidden;
  width: 0;
  padding: var(--navbar-padding);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: right;

  &[data-state='open'] {
    display: block;
    visibility: visible;
    width: 100%;
  }

  @include breakpoint($navbar-breakpoint) {
    --navbar-background: var(--color-bg-canvas);
    --navbar-padding: 0;
    --navbar-item-color: var(--color-text-primary);
    --navbar-item-display: inline-block;
    --navbar-item-margin: 0 #{rem-baseline(2)};
    box-shadow: none;
    justify-self: end;
    display: block;
    padding: var(--navbar-padding);
    position: relative;
    visibility: visible;
    width: auto;
  }

  @include breakpoint(xxlarge) {
    --navbar-item-margin: 0 #{rem-baseline(3)};
  }

  // TODO: Refactor this!
  .button {
    @include u-baseline(2, margin-bottom);
    margin-right: 100%;

    @include breakpoint($navbar-breakpoint) {
      margin: 0;
      margin-left: rem-baseline(2);
    }

    @include breakpoint(xxlarge) {
      margin-left: rem-baseline(3);
    }
  }
}

.navbar__item {
  color: var(--navbar-item-color);
  display: var(--navbar-item-display);
  font: var(--navbar-font, var(--text-menu));
  letter-spacing: rem-calc(1.5);
  margin: var(--navbar-item-margin);
  position: relative;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  width: fit-content;

  &:hover,
  &[data-state='active'] {
    &:after {
      background: var(--gradient-border);
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: rem-calc(3);
      left: 0;
      bottom: -#{rem-baseline(1)};
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.navbar__close {
  @include u-baseline(4, width);
  @include u-baseline(4, height);
  background: rgba(255, 255, 255, .2);
  border: none;
  border-radius: var(--border-radius-l);
  color: var(--color-alabaster);
  padding: 0;

  @include breakpoint($navbar-breakpoint) {
    display: none;
    visibility: hidden;
    width: 0;
  }

  &:focus {
    border: 1px solid var(--color-spray);
  }

  .c-icon {
    width: 2em;
    height: 2em;
  }
}

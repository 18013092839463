@use "sass:math";
@use "sass:map";
$baseline: map.get($global-config, 'baseline');
$breakpoints: map.get($global-config, 'breakpoints') !default;

// Using margins and paddings based on $baseline
// to ensure vertical and horizontal grid
@mixin u-baseline($size: 1, $type: margin) {
  $baseline: #{$baseline * (math.div($size, 16))}rem;

  #{$type}: $baseline;
}

@mixin u-font-baseline($size, $lineheight) {
  font-size: rem-calc($size);
  line-height: $lineheight / $size;
}

// Breakpoint mixin
@mixin breakpoint($val: small) {
    // Size or keyword
  $bp: nth($val, 1);
  // Value for max-width media queries
  $bpMax: 0;
  // Direction of media query (up, down, or only)
  $dir: if(length($val) > 1, nth($val, 2), up);
  // Eventual output
  $str: 'only screen';
  // Is it a named media query?
  $named: false;

  // Orientation media queries have a unique syntax
  @if $bp == 'landscape' or $bp == 'portrait' {
    $str: $str + ' and (orientation: #{$bp})';
  }

  @else {
    // Try to pull a named breakpoint out of the $breakpoints map
    @if type-of($bp) == 'string' {
      @if map-has-key($breakpoints, $bp) {
        @if $dir == 'only' {
          $next-bp: map-next($breakpoints, $bp);
          @if $next-bp == null {
            $bpMax: null;
          }
          @else {
            $bpMax: $next-bp - (1/16);
          }
        }
        $bp: map-get($breakpoints, $bp);
        $named: true;
      }
      @else {
        $bp: 0;
      }
    }

    // Pixel and unitless values are converted to rems
    @if unit($bp) == 'px' or unit($bp) == '' {
      $bp: rem-calc($bp);
    }
    // Finally, the rem value is turned into an em value
    $bp: strip-unit($bp) * 1em;

    // Skip media query creation if the input is "0 up" or "0 down"
    @if $bp > 0 or $dir == 'only' {
      // And lo, a media query was born
      @if $dir == 'only' {
        @if $named == true {
          $str: $str + ' and (min-width: #{$bp})';
          @if $bpMax != null {
            $str: $str + ' and (max-width: #{$bpMax})';
          }
        }
        @else {
          @debug 'ERROR: Only named media queries can have an "only" range.';
        }
      }
      @else if $dir == 'down' {
        $max: $bp - math.div(1, 16);
        $str: $str + ' and (max-width: #{$max})';
      }
      @else {
        $str: $str + ' and (min-width: #{$bp})';
      }
    }
  }

  // Output
  @if $bp == 0em and $dir != 'only' {
    @content;
  }
  @else {
    @media #{$str} {
      @content;
    }
  }
}

// Visibility
// @param {String} $mode
@mixin a11y-visibility($mode: 'hidden') {
  @if $mode == 'hidden' {
    display: none;
    visibility: hidden;
    width: 0;
  }
}

.editorial {
  --editorial-grid-areas: 'content' 'sidebar' 'menu';
  --editorial-gap: #{rem-baseline(5)} 0;
  --editorial-grid-rows: auto auto auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--editorial-grid-rows);
  gap: var(--editorial-gap);
  grid-auto-rows: auto;
  grid-auto-flow: row;
  justify-content: start;
  justify-items: start;
  align-items: start;
  grid-template-areas: var(--editorial-grid-areas);
  margin-bottom: var(--block-margin);

  @include breakpoint($navbar-breakpoint) {
    --editorial-gap: 0 #{rem-baseline(16)};
    --editorial-grid-areas: 'content menu' 'content sidebar';
    --editorial-grid-rows: min-content 1fr;
    grid-template-columns: 1.3fr 0.7fr;
  }

  &:not(:has(.editorial__sidebar)) {
    --editorial-grid-areas: 'content' 'menu';
    --editorial-grid-rows: auto auto;

    @include breakpoint($navbar-breakpoint) {
      --editorial-grid-areas: 'content menu';
      --editorial-grid-rows: auto;
    }
  }

  &:not(:has(.editorial__menu)) {
    --editorial-grid-areas: 'content' 'sidebar';
    --editorial-grid-rows: auto auto;

    @include breakpoint($navbar-breakpoint) {
      --editorial-grid-areas: 'content sidebar';
      --editorial-grid-rows: auto;
    }
  }

  &:not(:has(.editorial__sidebar)):not(:has(.editorial__menu)) {
    --editorial-grid-areas: 'content';
    --editorial-grid-rows: auto;
  }

  &[data-layout='switch'] {
    @include breakpoint($navbar-breakpoint) {
      grid-template-columns: 0.7fr 1.3fr;
      grid-template-areas:
        'menu content'
        'sidebar content';
    }

    &:not(:has(.editorial__menu)) {
      @include breakpoint($navbar-breakpoint) {
        grid-template-areas:
        'sidebar content';
      }
    }
  }

  &[data-type='mobile-switch'] {
    .editorial__menu {
      grid-row: 1;
    }

    .editorial__content {
      grid-row: 2;

      @include breakpoint($navbar-breakpoint) {
        grid-row: 1;
      }
    }
  }
}

.editorial__content {
  grid-area: content;
  width: 100%;

  p {
    max-width: 70ch;
  }
}

.editorial__menu {
  grid-area: menu;
  width: 100%;
}

.editorial__sidebar {
  align-self: var(--editorial-align-self);
  grid-area: sidebar;
  width: 100%;

  @include breakpoint($navbar-breakpoint) {
    height: var(--editorial-sidebar-height);
  }
}

.highlight {
  @include u-baseline(4, padding);
  @include u-baseline(5, padding-bottom);
  background-color: var(--highlight-bg, var(--color-white));
  border-left: rem-baseline(1) solid var(--highlight-border, var(--color-polar));
  border-radius: rem-calc(6);
  margin-left: auto;
  margin-right: auto;
  // max-width: rem-calc(900);

  p, ul, ol {
    max-width: 70ch;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &[data-style='important'] {
    --highlight-bg: var(--color-polar);
    --highlight-border: var(--color-charlotte);
  }
}

.foobar {
  container-type: inline-size;
  width: 100%;
}

@container (min-width: 500px) {
  .foobar__menu {
    @include u-baseline(2, column-gap);
    columns: 2 auto;
  }
}

.foobar__item {
  @include u-baseline(2, margin-bottom);
  color: var(--color-text-invert);
  display: block;
  font: var(--text-menu);
  letter-spacing: rem-calc(1.5);
  text-decoration: none;
  text-transform: uppercase;
}

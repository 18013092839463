.pagehead {
  margin-bottom: var(--block-margin);

  &[data-type='image'] .pagehead__mask {
    --pagehead-padding-top: 0;
    --pagehead-padding-bottom: 0;
    --pagehead-minheight: 0;
  }

  figure {
    margin: 0;
  }

  figcaption {
    @include u-baseline(2, margin-top);
    @include u-baseline(3, padding-right);
    color: var(--color-manatee);
    font: var(--text-tiny);
    text-align: right;
  }
}

.pagehead__mask {
  aspect-ratio: var(--pagehead-aspect-ratio);
  background-color: var(--color-geyser);
  display: grid;
  align-items: end;
  border-radius: rem-calc(3);
  mask-image: url(../svg/mask-pagehead.svg);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  min-height: var(--pagehead-minheight, #{rem-baseline(20)});
  padding-top: var(--pagehead-padding-top, #{rem-baseline(8)});
  padding-bottom: var(--pagehead-padding-bottom, #{rem-baseline(4)});

  @include breakpoint($navbar-breakpoint) {
    --pagehead-padding-top: #{rem-baseline(10)};
    --pagehead-padding-bottom: #{rem-baseline(12)};
    --pagehead-minheight: #{rem-baseline(50)};
  }

  img {
    width: 100%;
    height: auto;
  }
}

.footer {
  --grid-row-gap: #{rem-baseline(6)};
  --footer-padding-top: #{rem-baseline(10)};
  mask-image: url(../svg/mask-footer.svg);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  padding-top: var(--footer-padding-top);
  padding-bottom: var(--footer-padding-bottom, #{rem-baseline(8)});

  @include breakpoint($navbar-breakpoint) {
    --footer-padding-top: #{rem-baseline(20)};
    --footer-padding-bottom: #{rem-baseline(15)};
  }

  .branding {
    --grid-area-xlarge: 1 / grid-start / 1 / 8;
  }

  .foobar {
    --grid-area-small: 2 / grid-start / 2 / grid-end;
    --grid-area-xlarge: 1 / 8 / 1 / grid-end;
  }
}

.contact + .footer {
  margin-top: calc(var(--footer-padding-top) * -1);
}

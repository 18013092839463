.branding {
  --svg-width: #{rem-calc(146)};
  --svg-height: #{rem-baseline(3)};
  width: 100%;

  a {
    color: var(--color-text-primary);
    display: inline-block;
  }

  svg {
    transition: all .1s ease;
  }

  @include breakpoint($navbar-breakpoint) {
    --svg-width: #{rem-calc(175)};
    --svg-height: #{rem-baseline(4)};
  }

  @include breakpoint(xxlarge) {
    --svg-width: #{rem-calc(248)};
    --svg-height: #{rem-baseline(5)};
  }
}

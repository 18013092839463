.linkgroup {
  font-size: 0;
  text-align: center;

  &:has(.button) {
    --button-margin: 0 #{rem-baseline(2)} #{rem-baseline(2)};
  }

  &:not(:has(.button)) {
    --link-margin: 0 #{rem-baseline(2)} #{rem-baseline(2)};
  }
}

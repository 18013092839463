.label {
  --label-bg: var(--color-mercury);
  --label-color: var(--color-text-primary);
  background-color: var(--label-bg);
  color: var(--label-color);
  border-radius: rem-calc(4);
  display: inline-block;
  font: var(--text-label);
  padding: 0 rem-calc(10);
  letter-spacing: .1875em;
  text-transform: uppercase;
}

a.label {
  text-decoration: none;

  &:hover {
    background-color: var(--color-highlight);
  }
}

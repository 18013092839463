.columns {
  display: grid;
  gap: var(--columns-gap, #{rem-baseline(3)});
  margin-left: auto;
  margin-right: auto;
  max-width: var(--columns-max-width, #{rem-calc(640)});
  text-align: var(--columns-text-align, center);
  width: 100%;

  @include breakpoint(xlarge) {
    --columns-gap: #{rem-baseline(8)};
    --columns-text-align: left;
    --columns-max-width: none;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: top;
  }
}

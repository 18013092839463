.pt-box--s {
  padding-top: var(--padding-top, #{rem-baseline(3)});

  @include breakpoint($navbar-breakpoint) {
    --padding-top: #{rem-baseline(7)};
  }
}

.pb-box--s {
  padding-bottom: var(--padding-bottom, #{rem-baseline(5)});

  @include breakpoint($navbar-breakpoint) {
    --padding-bottom: #{rem-baseline(9)};
  }
}

.pt-box--l {
  padding-top: var(--padding-top, #{rem-baseline(5)});

  @include breakpoint($navbar-breakpoint) {
    --padding-top: #{rem-baseline(10)};
  }
}

.pb-box--l {
  padding-bottom: var(--padding-bottom, #{rem-baseline(7)});

  @include breakpoint($navbar-breakpoint) {
    --padding-bottom: #{rem-baseline(12)};
  }
}

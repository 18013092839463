.listicle {
  --listicle-areas: 'content' 'sidebar';
  --listicle-columns: 1fr;
  @include u-baseline(5, margin-bottom);
  @include u-baseline(5, padding-bottom);
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-template-columns: var(--listicle-columns);
  grid-template-rows: 1fr;
  grid-template-areas: var(--listicle-areas);
  gap: rem-baseline(3);

  @include breakpoint(xlarge) {
    --listicle-areas: 'content sidebar';
    --listicle-columns: minmax(300px, 70%) minmax(300px, 30%);
  }
}

.listicle__content {
  grid-area: content;
}

.listicle__sidebar {
  justify-self: end;
  grid-area: sidebar;
}

// Custom variables and properties
// – must not depend on general logic
@use "sass:map";
@use "sass:math";

// Custom variables
$navbar-breakpoint: map.get($global-config, 'navbar-breakpoint');
$feature-breakpoint: map.get($global-config, 'feature-breakpoint');
$content-gutter: rem-calc(map.get($global-config, 'layout-gutter'));
$content-max-width: rem-calc(map.get($global-config, 'layout-max-width'));

// Generate custom properties
:root {
  // Colors
  @each $name, $value in map.get($global-config, 'colors') {
    --color-#{$name}: #{$value};
  }

  // Gradients
  @each $name, $value in map.get($global-config, 'gradients') {
    --gradient-#{$name}: #{$value};
  }

  // Fonts
  @each $name, $value in map.get($global-config, 'fonts') {
    --font-#{$name}: #{$value};
  }

  // Border
  @each $name, $value in map.get($global-config, 'border') {
    --border-#{$name}: #{rem-calc($value)};
  }
}



// Custom properties
:root {
  --border-radius-xl: #{rem-calc(6)};
  --color-text-primary: var(--color-charade);
  --color-text-title: var(--color-text-primary);
  --color-text-subtitle: var(--color-shuttle);
  --color-text-breadcrumb: var(--color-shuttle);
  --color-highlight: var(--color-charlotte);
  --heading-title:          400 #{font-clamp(28, 4.5vw, 48, 56)} var(--font-heading);
  --heading-subtitle:       200 #{font-clamp(22, 3vw, 30, 40)} var(--font-heading);
  --heading-breadcrumb:     200 #{font-clamp(14, 2.5vw, 18, 24)} var(--font-heading);
  --heading-meta:           400 #{font-clamp(22, 2.5vw, 26, 40)} var(--font-heading);
  --heading-large-teaser:   400 #{font-clamp(20, 3.5vw, 36, 40)} var(--font-heading);
  --heading-copy:           400 #{font-clamp(24, 3vw, 28, 40)} var(--font-heading);
  --heading-item:           600 #{font-clamp(20, 5vw, 24, 32)} var(--font-heading);
  --heading-item-small:     600 #{font-clamp(18, 5vw, 22, 32)} var(--font-heading);
  --text-large-teaser:      300 #{font-clamp(20, 3vw, 26, 40)} var(--font-heading);
  --text-button:            600 #{font-clamp(16, 3vw, 20, 24)} var(--font-heading);
  --text-button-small:      600 #{font-clamp(16, 3vw, 18, 24)} var(--font-heading);
  --text-copy:              300 #{font-clamp(16, 3vw, 22, 40)} var(--font-copy);
  --text-quote:             300 #{font-clamp(20, 5vw, 40, 48)} var(--font-heading);
  --text-item:              300 italic #{font-clamp(18, 3vw, 20, 32)} var(--font-copy);
  --text-meta:              300 italic #{font-clamp(16, 3vw, 18, 32)} var(--font-copy);
  --text-tiny:              300 italic #{rem-calc(18)}/1.5 var(--font-copy);
  --text-meta-small:        200 #{font-clamp(16, 2.5vw, 18, 32)} var(--font-heading);
  --text-link:              600 #{font-clamp(16, 2vw, 20, 40)} var(--font-heading);
  --text-link-large:        600 #{font-clamp(16, 2.5vw, 22, 32)} var(--font-heading);
  --text-menu:              #{font-calc(16, 24)} var(--font-heading);
  --text-menu-tiny:         #{font-calc(14, 24)} var(--font-heading);
  --text-label:             #{font-calc(11, 24)} var(--font-heading);
  --shadow-stage: 1px 2px 2px rgba(0, 0, 0, 0.02),
                  2px 4px 4px rgba(0, 0, 0, 0.02),
                  4px 8px 8px rgba(0, 0, 0, 0.02),
                  8px 16px 16px rgba(0, 0, 0, 0.02),
                  16px 32px 32px rgba(0, 0, 0, 0.02);
  --shadow-stage-dark: 1px 2px 2px rgba(0, 0, 0, 0.07),
                  2px 4px 4px rgba(0, 0, 0, 0.07),
                  4px 8px 8px rgba(0, 0, 0, 0.07),
                  8px 16px 16px rgba(0, 0, 0, 0.07),
                  16px 32px 32px rgba(0, 0, 0, 0.07);
  --shadow-stage-ultra-dark: 1px 2px 2px rgba(0, 0, 0, 0.1),
                  2px 4px 4px rgba(0, 0, 0, 0.1),
                  4px 8px 8px rgba(0, 0, 0, 0.1),
                  8px 16px 16px rgba(0, 0, 0, 0.1),
                  16px 32px 32px rgba(0, 0, 0, 0.1);
  --shadow-high-centered: 0 2px 2px rgba(0, 0, 0, 0.15),
                0 4px 4px rgba(0, 0, 0, 0.15),
                0 8px 8px rgba(0, 0, 0, 0.15),
                0 16px 16px rgba(0, 0, 0, 0.15),
                0 32px 32px rgba(0, 0, 0, 0.15);
  --shadow-button: 0px 1px 1px rgba(0, 0, 0, 0.12),
                0px 2px 2px rgba(0, 0, 0, 0.12),
                0px 4px 4px rgba(0, 0, 0, 0.12),
                0px 8px 8px rgba(0, 0, 0, 0.12),
                0px 16px 16px rgba(0, 0, 0, 0.12);
  --shadow-button-hover: 0px 2px 1px rgba(0, 0, 0, 0.16),
                0px 4px 2px rgba(0, 0, 0, 0.16),
                0px 6px 4px rgba(0, 0, 0, 0.16),
                0px 10px 8px rgba(0, 0, 0, 0.16),
                0px 18px 16px rgba(0, 0, 0, 0.16);
  --gradient-charade: linear-gradient(212.08deg, #404754 10%, var(--color-charade) 89.12%);
  --gradient-highlight: linear-gradient(83.38deg, #2F3542 31.83%, #364352 76.47%), linear-gradient(83.38deg, #2F3542 31.83%, #2F3D4D 76.47%);
  --header-height: #{rem-baseline(9)};
  --block-margin: #{rem-baseline(5)};

  @include breakpoint($navbar-breakpoint) {
    --header-height: #{rem-baseline(11)};
    --block-margin: #{rem-baseline(10)};
  }

  @include breakpoint(xxlarge) {
    --header-height: #{rem-baseline(15)};
  }
}

.card {
  --card-padding: #{rem-baseline(4)} #{rem-baseline(3)};
  --card-heading: var(--heading-item);
  border-radius: rem-calc(6);
  container: card / inline-size;
  box-shadow: var(--card-shadow, var(--shadow-stage));
  display: grid;
  grid-template-rows: auto auto 1fr;
  overflow: hidden;
  position: relative;
  transition: all .05s ease;

  &:not([data-style='highlight']):hover {
    --card-shadow: var(--shadow-stage-dark);
    transform: translateY(-.15em);
  }

  &[data-style='highlight'] {
    --card-shadow: var(--shadow-stage-dark);
    --link-color: var(--color-white);
  }
}

.card__image {
  aspect-ratio: var(--aspect-ratio);
  background: linear-gradient(237.23deg, #767D89 49.5%, #4E545E 96.35%);
  display: grid;
  justify-items: center;
  align-items: center;

  img {
    display: block;
    max-width: var(--max-size, 100%);
    max-height: var(--max-size, 100%);
  }
}

.card__label {
  @include u-baseline(2, gap);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  margin-top: var(--card-label-pull, -0.75em);
  min-height: var(--card-label-minheight, #{rem-baseline(3)});
  display: flex;
  justify-content: flex-end;
}

.card__content {
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: var(--card-padding);
}

.card__heading {
  @include u-baseline(1, margin-bottom);
  font: var(--card-heading);
  margin-top: 0;
}

.card__body {
  @include u-baseline(3, margin-bottom);
  font: var(--text-item);
  text-wrap: balance;
}

@container card (max-width: #{rem-calc(370)}) {
  .card__label {
    --card-label-pull: -.75em;
    --card-label-minheight: #{rem-baseline(3)};
  }

  .card__content {
    --card-padding: #{rem-baseline(3)} #{rem-baseline(2)};
  }

  .card__heading {
    --card-heading: var(--heading-item-small);
  }

  .card__body {
    --text-item: var(--text-meta);
  }
}

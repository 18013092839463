/* outfit-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/outfit-v11-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* outfit-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/outfit-v11-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* outfit-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/outfit-v11-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/source-sans-3-v15-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* source-sans-3-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/source-sans-3-v15-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* source-sans-3-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/source-sans-3-v15-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* source-sans-3-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/source-sans-3-v15-latin-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

[class*='heading-'] {
  margin-top: 0;
  margin-bottom: var(--heading-margin, #{rem-baseline(2)});
  text-wrap: balance;

  a {
    color: currentColor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.heading-large-teaser {
  @include u-baseline(3, margin-bottom);
  font: var(--heading-large-teaser);
}

.heading-title {
  color: var(--color-text-title);
  font: var(--heading-title);
}

.heading-subtitle {
  color: var(--color-text-subtitle);
  font: var(--heading-subtitle);
}

.heading-home-title {
  color: var(--color-text-title);
  font: var(--heading-title);
  text-align: center;

  @include breakpoint(xlarge) {
    text-align: left;
  }
}

.heading-home-subtitle {
  color: var(--color-text-subtitle);
  font: var(--heading-subtitle);
  text-align: center;

  @include breakpoint(xlarge) {
    text-align: left;
  }
}

.heading-item {
  font: var(--heading-item);
}

.heading-item-small {
  --heading-margin: #{rem-baseline(4)};
  font: var(--heading-item-small);
}

.heading-breadcrumb {
  color: var(--color-text-breadcrumb);
  font: var(--heading-breadcrumb);
  letter-spacing: .125em;
  text-transform: uppercase;
}

.heading-copy {
  color: var(--color-heading-copy);
  font: var(--heading-copy);
}

.heading-meta {
  font: var(--heading-meta);
}

.heading-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: rem-calc(720);
}

.text-copy {
  font: var(--text-copy);

  strong {
    font-weight: 600;
  }

  a:not([class]) {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    @include u-baseline(4, margin-top);
    @include u-baseline(5, margin-bottom);

    &:first-child {
      margin-top: 0;
    }
  }

  h2, h3, h4 {
    @include u-baseline(2, margin-bottom);
    font: var(--heading-copy);
    margin-top: 0;
    text-wrap: balance;
  }

  ul, ol {
    @include u-baseline(3, padding-left);
    margin: 0;
  }
}

.text-meta {
  font: var(--text-meta);

  strong {
    font-weight: 600;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    @include u-baseline(2, margin-bottom);
    margin-top: 0;
  }

  ul, ol {
    @include u-baseline(3, padding-left);
    margin: 0;
  }
}

.text-large-teaser {
  color: var(--color-text-subtitle);
  font: var(--text-large-teaser);

  p {
    @include u-baseline(4, margin-bottom);
    margin-top: 0;
  }
}

.text-meta-small {
  font: var(--text-meta-small);

  strong {
    font-weight: 600;
  }
}

.text-item {
  font: var(--text-item);
}

.text-center {
  text-align: center;
}

.text-normal {
  font-weight: 300;
}

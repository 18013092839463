/* Basics */
html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: var(--color-bg-canvas);
  color: var(--color-text-primary);
  font-family: var(--font-copy);
  font-size: 100%;
  font-weight: normal;
  height: 100%;
  line-height: 1.5;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  padding-top: var(--header-height);
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  width: 100%;
}

svg {
  display: inline-block;
  fill: currentColor;
  overflow: hidden;
  vertical-align: middle;
  width: var(--svg-width, #{rem-baseline(2)});
  height: var(--svg-height, #{rem-baseline(2)});
}

img {
  aspect-ratio: var(--aspect-ratio);
  max-width: 100%;
  height: auto;

  &:not([data-nobg]) {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWFlMWY3Ij48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=");
  }
}

p {
  @include u-baseline(2, margin-top);
  @include u-baseline(3, margin-bottom);
}

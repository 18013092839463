.menu {
  --menu-text-color: var(--color-shuttle);
  --svg-width: #{rem-calc(31)};
  --svg-height: #{rem-calc(14)};
  background: none;
  border: none;
  color: var(--menu-text-color);
  cursor: pointer;
  font-size: rem-calc(8);
  justify-self: end;
  letter-spacing: rem-calc(2);
  padding: 0;
  text-transform: uppercase;

  p {
    @include u-baseline(1, line-height);
    margin: rem-calc(6) 0 0;
  }

  @include breakpoint($navbar-breakpoint) {
    @include a11y-visibility('hidden');
  }
}

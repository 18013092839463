.quote {
  margin: var(--quote-margin, #{rem-baseline(4)}) auto;
  max-width: rem-calc(900);
  text-align: center;

  @include breakpoint(large) {
    --quote-margin: #{rem-baseline(10)};
  }

  p {
    font: var(--text-quote);
    margin: 0;
    text-wrap: balance;
  }

  footer {
    @include u-baseline(2, margin-top);
    color: var(--color-shuttle);
    font: var(--heading-item);

    &:before {
      content: '— ';
    }
  }
}

.border-bottom {
  border-bottom: 1px solid var(--color-alto);
}

.img-round {
  border-radius: 50%;
}

.img-mask {
  border-radius: rem-calc(3);
  mask-image: url(../svg/mask-pagehead.svg);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}

.flip-hor {
  transform: scaleX(-1);
}

.show-for-xl {
  @include breakpoint(large down) {
    display: none !important;
  }
}

.show-for-xxl {
  @include breakpoint(xlarge down) {
    display: none !important;
  }
}

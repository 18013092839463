.profile {
  --profile-cols: 1fr;
  --profile-body-col: 1fr;
  --profile-gap: #{rem-baseline(3)};
  container: profile / inline-size;
  display: grid;
  color: inherit;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-template-columns: var(--profile-cols);
  grid-template-rows: 1fr;
  gap: var(--profile-gap);
  position: relative;
  text-decoration: none;

  &:has(a):hover {
    .profile__img img {
      box-shadow: var(--shadow-stage);
      transform: translateY(-.075em);
    }
  }

  @container block (min-width: #{rem-calc(470)}) {
    --profile-cols: minmax(200px, 240px) minmax(200px, 300px);
    --profile-gap: #{rem-baseline(5)};
    --profile-text-align: left;
  }

  @container block (min-width: #{rem-calc(780)}) {
    --profile-gap: #{rem-baseline(8)};
  }

  @container block (min-width: #{rem-calc(830)}) {
    --profile-cols: max-content minmax(200px, 530px);
  }
}

.profile__img {
  justify-self: center;
  max-width: var(--profile-img-max, #{rem-calc(260)});

  @include breakpoint(large) {
    --profile-img-max: #{rem-calc(340)};
  }

  img {
    border: 8px solid white;
    display: block;
    transition: all .075s ease;
  }
}

.profile__content {
  align-self: center;
  text-align: var(--profile-text-align, center);
}

/** ***********
 * Main content wrapper with optional grid breakouts
 *
 * @usage <div class="block" data-layout="feature" />
 */
@use "sass:map";
$content-gutter: 24px !default;
$content-gutter-large: 48px !default;
$content-max-width: 800px !default;

@mixin composition-block($mixin: 0) {
  --gap: clamp(#{$content-gutter}, 6vw, #{$content-gutter});
  --full: minmax(var(--gap), 1fr);
  --content: min(#{$content-max-width}, 100% - var(--gap) * 2);
  --feature: minmax(0, 5rem);
  --narrow: minmax(0, 3rem);
  display: grid;
  position: relative;

  @include breakpoint($navbar-breakpoint) {
    --gap: clamp(#{$content-gutter-large}, 6vw, #{$content-gutter-large});
  }

  @if $mixin != 1 {
    grid-template-columns:
    [full-start] var(--full)
    [feature-start] var(--feature)
    [content-start] var(--content) [content-end]
    var(--feature) [feature-end]
    var(--full) [full-end];
  }

  @if $mixin == 1 {
    grid-template-columns:
      minmax(var(--gap), 1fr)
      minmax(var(--gap), #{$content-max-width})
      minmax(var(--gap), 1fr);
    grid-template-areas: '. content .';

    > * {
      grid-area: content;
    }
  }

  & > * {
    grid-column: content;
  }

  &[data-layout='feature'] > * {
    grid-column: full;

    @include breakpoint($feature-breakpoint) {
      grid-column: feature;
    }
  }

  &[data-layout='full'] > * {
    grid-column: full;
  }

  &[data-layout='narrow'] > * {
    justify-self: center;
    max-width: rem-calc(900);
  }

  &[data-width='full'] > * {
    width: 100%;
  }
}

.block {
  @include composition-block;
  background: var(--block-bg);
  color: var(--block-color);

  &[data-spacer='large'] {
    margin-bottom: var(--block-margin);
  }

  &[data-spacer='xlarge'] {
    @include u-baseline(8, margin-bottom);

    @include breakpoint(large) {
      @include u-baseline(18, margin-bottom);
    }
  }
}

.block-item {
  container: block / inline-size;
  width: 100%;

  &:not(.no-margin) {
    margin-bottom: var(--block-margin);
  }
}

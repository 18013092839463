.iconlink {
  --iconlink-icon: url(/src/svg/icon-social-website.svg);
  color: currentColor;
  display: inline-block;
  font-size: 1em;
  padding-left: 2em;
  position: relative;

  &:hover {
    text-decoration: none;
  }

  &:before {
    background: var(--iconlink-icon);
    background-size: 100% 100%;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5em;
    height: 1.5em;
  }

  &[href*='linkedin.com'] {
    --iconlink-icon: url(/src/svg/icon-social-linkedin.svg);
  }

  &[href*='xing.com'] {
    --iconlink-icon: url(/src/svg/icon-social-xing.svg);
  }

  &[href*='mailto:'] {
    --iconlink-icon: url(/src/svg/icon-mail.svg);
  }
}

.flyer {
  --flyer-padding: #{rem-baseline(8)} #{rem-baseline(12)} #{rem-baseline(12)} #{rem-baseline(8)};
  --flyer-icon-color: var(--color-polar);
  --flyer-icon-size: #{rem-baseline(4)};
  background-color: var(--flyer-bg, var(--color-white));
  border-radius: rem-calc(6);
  box-shadow: var(--flyer-shadow, var(--shadow-stage));
  padding: var(--flyer-padding);
  position: relative;
  transition: all .075s ease;

  @include breakpoint(xxlarge) {
    --flyer-icon-size: #{rem-baseline(7)};
  }

  svg {
    @include u-baseline(3, margin-bottom);
    fill: var(--flyer-icon-color);
    width: var(--flyer-icon-size);
    height: var(--flyer-icon-size);
  }

  &[data-style='highlight'] {
    --flyer-shadow: var(--shadow-stage-dark);
    --flyer-icon-color: var(--color-shuttle);
    --link-color: var(--color-white);

    &:has(.link:hover) {
      --flyer-shadow: var(--shadow-stage-ultra-dark);
    }
  }

  &:has(.link:hover) {
    --flyer-shadow: var(--shadow-stage-dark);
    transform: translateY(-.15em);
  }
}

@container block (max-width: #{rem-calc(700)}) {
  .flyer {
    --flyer-padding: #{rem-baseline(3)} #{rem-baseline(3)} #{rem-baseline(5)};
  }
}

// Gradients
// .gradient-dark {
//   background: linear-gradient(206.75deg, #404754 2.09%, #2F3542 85.47%);
// }

// Text

.bg-canvas {
  background-color: var(--color-bg-canvas);
}

.bg-white {
  background-color: var(--color-white);
}

.color-invert {
  color: var(--color-text-invert);
}

.color-manatee {
  color: var(--color-manatee);
}

.gr-charade {
  background: var(--gradient-charade);
}

.gr-highlight {
  background: var(--gradient-highlight);
}

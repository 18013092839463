// /**
//  * Grid Loco
//  *
//  * A super flexible CSS grid using CSS grid and custom properties.
//  * Create your grid in your modules or on the fly in the DOM without
//  * stiff classes or mixins. Do what you want, but don't forget the omen!
//  *
//  * @author Martin Szymanski
//  * @link https://github.com/elfacht/grid-loco/
//  * @version tbd.
//  * @license tbd. but something MIT, I guess.
//  *
//  * @var {String} --grid-max (Grid max width in px)
//  * @var {String} --grid-gap-[<small>|<medium>|<large>|<xlarge>] (Gap between the columns in px)
//  * @var {Number} --grid-rows (Amount of grid rows)
//  * @var {Number} --grid-cols (Amount of grid cols)
//  * @var {String} --grid-align [<stretch>|<center>|<start>|<end>] (Align of all items inside grid)
//  * @var {String} --grid-height (Custom grid height)
//  * @var {Number} --grid-row-start
//  * @var {Number} --grid-row-start-[<small>|<medium>|<large>|<xlarge>]
//  * @var {Number} --grid-row-end
//  * @var {Number} --grid-row-end-[<small>|<medium>|<large>|<xlarge>]
//  * @var {Number} --grid-col-start
//  * @var {Number} --grid-col-start-[<small>|<medium>|<large>|<xlarge>]
//  * @var {Number} --grid-col-end
//  * @var {Number} --grid-col-end-[<small>|<medium>|<large>|<xlarge>]
//  * @var {Number} --grid-col-zindex
//  */

// // ––– Grid defaults –––
// // Set default grid max-width
// $grid-max-width: 1400px !default;

// // Set grid breakpoints
// $grid-breakpoints: (
//   small: 0,
//   medium: 572px,
//   large: 768px,
//   xlarge: 992px,
//   xxlarge: 1400px,
//   xxxlarge: 1600px
// ) !default;

// // Set Grid gap widths
// $grid-gap: (
//   small: 12px,
//   medium: 24px,
//   large: 24px,
//   xlarge: 24px,
//   xxlarge: 24px,
//   xxxlarge: 24px
// ) !default;

// .grid {
//   // Grid gap sizes per breakpoint
//   --grid-gap-default: #{map-get($grid-gap, 'small')};
//   --grid-gap-small: #{map-get($grid-gap, 'small')};
//   --grid-gap-medium: #{map-get($grid-gap, 'medium')};
//   --grid-gap-large: #{map-get($grid-gap, 'large')};
//   --grid-gap-xlarge: #{map-get($grid-gap, 'xlarge')};
//   --grid-gap-xxlarge: #{map-get($grid-gap, 'xxlarge')};
//   --grid-gap-xxxlarge: #{map-get($grid-gap, 'xxxlarge')};

//   // Grid gap default variable
//   --grid-gap: var(--grid-gap-small, var(--grid-gap-default));

//   // Calculate column width by grid max. width,
//   // column amount and grid gap
//   --grid-col-width: calc(calc(var(--grid-max, #{rem-calc($grid-max-width)}) - calc(var(--grid-cols, 12) * var(--grid-gap, var(--grid-gap-default)))) / var(--grid-cols, 12));

//   // Need to be a string, otherwise
//   // the linter will hurt you.
//   $grid-template-cols: '[start] minmax(0, 1fr) [grid-start] repeat(var(--grid-cols, 12), minmax(0, var(--grid-col-width))) [grid-end] minmax(0, 1fr) [end]';

//   // Box model (margin, padding)
//   --grid-margin-default: 0;
//   --grid-padding-default: 0;
//   --grid-margin: var(--grid-margin-small, var(--grid-margin-default));
//   --grid-padding: var(--grid-padding-small, var(--grid-padding-default));

//   margin-bottom: var(--grid-margin);
//   padding-top: var(--grid-padding);
//   padding-bottom: var(--grid-padding);

//   @include breakpoint(large) {
//     --grid-margin: var(--grid-margin-large, var(--grid-margin-small, var(--grid-margin-default)));
//     --grid-padding: var(--grid-padding-large, var(--grid-padding-small, var(--grid-padding-default)));
//   }

//   // Use grid only if not full-bleed
//   // &:not(.u-grid--full) {
//     align-items: var(--grid-align, start);
//     display: grid;
//     gap: var(--grid-gap);
//     grid-template-rows: repeat(var(--grid-rows, 1), var(--grid-height));
//     grid-template-columns: var(--grid-columns, #{$grid-template-cols});
//     justify-content: start;
//     justify-items: start;
//     row-gap: var(--grid-row-gap);

//     @include breakpoint(medium) {
//       --grid-gap: var(--grid-gap-medium, var(--grid-gap-small, var(--grid-gap-default)));
//     }

//     @include breakpoint(large) {
//       --grid-gap: var(--grid-gap-large, var(--grid-gap-medium, var(--grid-gap-small, var(--grid-gap-default))));
//     }

//     @include breakpoint(xlarge) {
//       --grid-gap: var(--grid-gap-xlarge, var(--grid-gap-large, var(--grid-gap-medium, var(--grid-gap-small, var(--grid-gap-default)))));
//     }

//     @include breakpoint(xxlarge) {
//       --grid-gap: var(--grid-gap-xxlarge, var(--grid-gap-xlarge, var(--grid-gap-large, var(--grid-gap-medium, var(--grid-gap-small, var(--grid-gap-default))))));
//     }

//     @include breakpoint(xxxlarge) {
//       --grid-gap: var(--grid-gap-xxxlarge, var(--grid-gap-xxlarge, var(--grid-gap-xlarge, var(--grid-gap-large, var(--grid-gap-medium, var(--grid-gap-small, var(--grid-gap-default)))))));
//     }
//   // }

//   > * {
//     // Element column positions
//     --grid-col-start-default: grid-start;
//     --grid-col-end-default: grid-end;
//     --grid-col-start: var(--grid-col-start-small, var(--grid-col-start-default));
//     --grid-col-end: var(--grid-col-end-small, var(--grid-col-end-default));

//     // Element row positions
//     --grid-row-start-default: auto;
//     --grid-row-end-default: auto;
//     --grid-row-start: var(--grid-row-start-small, var(--grid-row-start-default));
//     --grid-row-end: var(--grid-row-end-small, var(--grid-row-end-default));

//     align-self: var(--grid-align-self, stretch);
//     grid-column: var(--grid-col-start) / var(--grid-col-end);
//     grid-row: var(--grid-row-start) / var(--grid-row-end);
//     position: relative;
//     z-index: var(--grid-col-zindex);

//     @include breakpoint(medium) {
//       --grid-col-start: var(--grid-col-start-medium, var(--grid-col-start-small, var(--grid-col-start-default)));
//       --grid-col-end: var(--grid-col-end-medium, var(--grid-col-end-small, var(--grid-col-end-default)));
//       --grid-row-start: var(--grid-row-start-medium, var(--grid-row-start-small, var(--grid-row-start-default)));
//       --grid-row-end: var(--grid-row-end-medium, var(--grid-row-end-small, var(--grid-row-end-default)));
//     }

//     @include breakpoint(large) {
//       --grid-col-start: var(--grid-col-start-large, var(--grid-col-start-medium, var(--grid-col-start-small, var(--grid-col-start-default))));
//       --grid-col-end: var(--grid-col-end-large, var(--grid-col-end-medium, var(--grid-col-end-small, var(--grid-col-end-default))));
//       --grid-row-start: var(--grid-row-start-large, var(--grid-row-start-medium, var(--grid-row-start-small, var(--grid-row-start-default))));
//       --grid-row-end: var(--grid-row-end-large, var(--grid-row-end-medium, var(--grid-row-end-small, var(--grid-row-end-default))));
//     }

//     @include breakpoint(xlarge) {
//       --grid-col-start: var(--grid-col-start-xlarge, var(--grid-col-start-large, var(--grid-col-start-medium, var(--grid-col-start-small, var(--grid-col-start-default)))));
//       --grid-col-end: var(--grid-col-end-xlarge, var(--grid-col-end-large, var(--grid-col-end-medium, var(--grid-col-end-small, var(--grid-col-end-default)))));
//       --grid-row-start: var(--grid-row-start-xlarge, var(--grid-row-start-large, var(--grid-row-start-medium, var(--grid-row-start-small, var(--grid-row-start-default)))));
//       --grid-row-end: var(--grid-row-end-xlarge, var(--grid-row-end-large, var(--grid-row-end-medium, var(--grid-row-end-small, var(--grid-row-end-default)))));
//     }

//     @include breakpoint(xxlarge) {
//       --grid-col-start: var(--grid-col-start-xxlarge, var(--grid-col-start-xlarge, var(--grid-col-start-large, var(--grid-col-start-medium, var(--grid-col-start-small, var(--grid-col-start-default))))));
//       --grid-col-end: var(--grid-col-end-xxlarge, var(--grid-col-end-xlarge, var(--grid-col-end-large, var(--grid-col-end-medium, var(--grid-col-end-small, var(--grid-col-end-default))))));
//       --grid-row-start: var(--grid-row-start-xxlarge, var(--grid-row-start-xlarge, var(--grid-row-start-large, var(--grid-row-start-medium, var(--grid-row-start-small, var(--grid-row-start-default))))));
//       --grid-row-end: var(--grid-row-end-xxlarge, var(--grid-row-end-xlarge, var(--grid-row-end-large, var(--grid-row-end-medium, var(--grid-row-end-small, var(--grid-row-end-default))))));
//     }
//   }
// }








/**
 * Grid Loco
 *
 * A super flexible CSS grid using CSS grid and custom properties.
 * Create your grid in your modules or on the fly in the DOM without
 * stiff classes or mixins. Do what you want, but don't forget the omen!
 *
 * @author Martin Szymanski
 * @link https://github.com/elfacht/grid-loco/
 * @version tbd.
 * @license tbd. but something MIT, I guess.
 *
 * @var {String} --grid-max (Grid max width in px)
 * @var {String} --grid-gap-[<small>|<medium>|<large>|<xlarge>] (Gap between the columns in px)
 * @var {Number} --grid-rows (Amount of grid rows)
 * @var {Number} --grid-cols (Amount of grid cols)
 * @var {String} --grid-align [<stretch>|<center>|<start>|<end>] (Align of all items inside grid)
 * @var {String} --grid-height (Custom grid height)
 * @var {Number} --grid-row-start
 * @var {Number} --grid-row-start-[<small>|<medium>|<large>|<xlarge>]
 * @var {Number} --grid-row-end
 * @var {Number} --grid-row-end-[<small>|<medium>|<large>|<xlarge>]
 * @var {Number} --grid-col-start
 * @var {Number} --grid-col-start-[<small>|<medium>|<large>|<xlarge>]
 * @var {Number} --grid-col-end
 * @var {Number} --grid-col-end-[<small>|<medium>|<large>|<xlarge>]
 * @var {Number} --grid-col-zindex
 */

// ––– Grid defaults –––
// Set default grid max-width
$grid-max-width: 1400px !default;

// Set grid breakpoints
$grid-breakpoints: (
  small: 0,
  medium: 572px,
  large: 768px,
  xlarge: 992px,
  xxlarge: 1400px,
  xxxlarge: 1600px
) !default;

// Set Grid gap widths
$grid-gap: (
  small: 12px,
  medium: 24px,
  large: 24px,
  xlarge: 24px,
  xxlarge: 24px,
  xxxlarge: 24px
) !default;

.grid {
  // Grid gap sizes per breakpoint
  --grid-col-gap-default: #{map-get($grid-gap, 'small')};
  --grid-col-gap-medium: #{map-get($grid-gap, 'medium')};
  --grid-col-gap-large: #{map-get($grid-gap, 'large')};
  --grid-col-gap-xlarge: #{map-get($grid-gap, 'xlarge')};
  --grid-col-gap-xxlarge: #{map-get($grid-gap, 'xxlarge')};

  --grid-row-gap-default: #{map-get($grid-gap, 'small')};
  --grid-row-gap-medium: #{map-get($grid-gap, 'medium')};
  --grid-row-gap-large: #{map-get($grid-gap, 'large')};
  --grid-row-gap-xlarge: #{map-get($grid-gap, 'xlarge')};
  --grid-row-gap-xxlarge: #{map-get($grid-gap, 'xxlarge')};
  --grid-rows-default: auto;
  --grid-rows-medium: auto;
  --grid-rows-large: auto;
  --grid-rows-xlarge: auto;


  // Grid gap default variable
  --grid-col-gap: var(--grid-col-gap-small, var(--grid-col-gap-default));
  --grid-row-gap: var(--grid-row-gap-small, var(--grid-row-gap-default));
  --grid-col-width: calc(calc(var(--grid-max, #{rem-calc($grid-max-width)}) - calc(var(--grid-cols, 12) * var(--grid-col-gap, var(--grid-col-gap-default)))) / var(--grid-cols, 12));

  display: grid;
  gap: var(--grid-row-gap) var(--grid-col-gap);
  grid-template-columns: [start]
                          minmax(var(--grid-col-gap, 0), 1fr)
                        [grid-start]
                          repeat(var(--grid-cols, 12),
                          minmax(0, var(--grid-col-width)))
                        [grid-end]
                          minmax(var(--grid-col-gap, 0), 1fr)
                        [end];
  grid-template-rows: var(--grid-rows, var(--grid-rows-default));

  @media (min-width: #{map-get($grid-breakpoints, 'medium')}) {
    --grid-col-gap: var(--grid-col-gap-medium, var(--grid-col-gap-small, var(--grid-col-gap-default)));
    --grid-row-gap: var(--grid-row-gap-medium, var(--grid-row-gap-small, var(--grid-row-gap-default)));
    --grid-rows: var(--grid-rows-medium, var(--grid-rows-small, var(--grid-rows-default)));
  }

  @media (min-width: #{map-get($grid-breakpoints, 'large')}) {
    --grid-col-gap: var(--grid-col-gap-large, var(--grid-col-gap-medium, var(--grid-col-gap-small, var(--grid-col-gap-default))));
    --grid-row-gap: var(--grid-row-gap-large, var(--grid-row-gap-medium, var(--grid-row-gap-small, var(--grid-row-gap-default))));
    --grid-rows: var(--grid-rows-large, var(--grid-rows-medium, var(--grid-rows-small, var(--grid-rows-default))));
  }

  @media (min-width: #{map-get($grid-breakpoints, 'xlarge')}) {
    --grid-col-gap: var(--grid-col-gap-xlarge, var(--grid-col-gap-large, var(--grid-col-gap-medium, var(--grid-col-gap-small, var(--grid-col-gap-default)))));
    --grid-row-gap: var(--grid-row-gap-xlarge, var(--grid-row-gap-large, var(--grid-row-gap-medium, var(--grid-row-gap-small, var(--grid-row-gap-default)))));
    --grid-rows: var(--grid-rows-xlarge, var(--grid-rows-large, var(--grid-rows-medium, var(--grid-rows-small, var(--grid-rows-default)))));
  }

  @media (min-width: #{map-get($grid-breakpoints, 'xxlarge')}) {
    --grid-col-gap: var(--grid-col-gap-xxlarge, var(--grid-col-gap-xlarge, var(--grid-col-gap-large, var(--grid-col-gap-medium, var(--grid-col-gap-small, var(--grid-col-gap-default))))));
    --grid-row-gap: var(--grid-row-gap-xxlarge, var(--grid-row-gap-xlarge, var(--grid-row-gap-large, var(--grid-row-gap-medium, var(--grid-row-gap-small, var(--grid-row-gap-default))))));
    --grid-rows: var(--grid-rows-xxlarge, var(--grid-rows-xlarge, var(--grid-rows-large, var(--grid-rows-medium, var(--grid-rows-small, var(--grid-rows-default))))));
  }

  > * {
    --grid-area-default: 1 / grid-start / 1 / grid-end;
    --grid-area: var(--grid-area-small, var(--grid-area-default));
    align-self: var(--grid-align-self, stretch);
    grid-area: var(--grid-area);
    position: relative;
    z-index: var(--grid-col-zindex);

    @media (min-width: #{map-get($grid-breakpoints, 'medium')}) {
      --grid-area: var(--grid-area-medium, var(--grid-area-small, var(--grid-area-default)));
    }

    @media (min-width: #{map-get($grid-breakpoints, 'large')}) {
      --grid-area: var(--grid-area-large, var(--grid-area-medium, var(--grid-area-small, var(--grid-area-default))));
    }

    @media (min-width: #{map-get($grid-breakpoints, 'xlarge')}) {
      --grid-area: var(--grid-area-xlarge, var(--grid-area-large, var(--grid-area-medium, var(--grid-area-small, var(--grid-area-default)))));
    }

    @media (min-width: #{map-get($grid-breakpoints, 'xxlarge')}) {
      --grid-area: var(--grid-area-xxlarge, var(--grid-area-xlarge, var(--grid-area-large, var(--grid-area-medium, var(--grid-area-small, var(--grid-area-default))))));
    }
  }
}

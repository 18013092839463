.list-plain {
  list-style: none;
  margin-bottom: var(--block-margin);
  padding: 0;

  > li {
    @include u-baseline(2, margin-bottom);
  }
}

.list-items {
  list-style: none;
  margin: rem-baseline(5) 0 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  gap: var(--list-gap, #{rem-baseline(3)});
  align-items: center;
  justify-content: center;

  @include breakpoint(large) {
    --list-gap: #{rem-baseline(5)};
  }

  > * {
    flex: 0 1 120px;

    @include breakpoint(large) {
      flex: 0 1 200px;
    }
  }
}

:root {
  --color-charade: #2f3542;
  --color-oxford: #2f3d4d;
  --color-shuttle: #57606f;
  --color-manatee: #959da8;
  --color-geyser: #ced6e0;
  --color-alto: #dedede;
  --color-mercury: #e8e8e8;
  --color-gallery: #f0f0f0;
  --color-alabaster: #fbfbfb;
  --color-white: #fff;
  --color-sail: #9bd4f6;
  --color-charlotte: #9ae1f7;
  --color-spray: #7be0ed;
  --color-polar: #d4f2f6;
  --color-bg-canvas: var(--color-alabaster);
  --color-text-primary: var(--color-charade);
  --color-text-invert: var(--color-alabaster);
  --gradient-border: linear-gradient(258.46deg, #7bdfed -3.91%, #9cd5f6 98.48%);
  --gradient-dark: linear-gradient(83.38deg, #2f3542 31.83%, #364352 76.47%), linear-gradient(83.38deg, #2f3542 31.83%, #2f3d4d 76.47%);
  --font-heading: var(--user-font-heading, "Outfit", sans-serif);
  --font-copy: var(--user-font-copy, "Source Sans 3", sans-serif);
  --border-radius-l: .25rem;
  --border-radius-xl: .375rem;
  --color-text-title: var(--color-text-primary);
  --color-text-subtitle: var(--color-shuttle);
  --color-text-breadcrumb: var(--color-shuttle);
  --color-highlight: var(--color-charlotte);
  --heading-title: 400 clamp(1.75rem, 4.5vw, 3rem) / 1.16667 var(--font-heading);
  --heading-subtitle: 200 clamp(1.375rem, 3vw, 1.875rem) / 1.33333 var(--font-heading);
  --heading-breadcrumb: 200 clamp(.875rem, 2.5vw, 1.125rem) / 1.33333 var(--font-heading);
  --heading-meta: 400 clamp(1.375rem, 2.5vw, 1.625rem) / 1.53846 var(--font-heading);
  --heading-large-teaser: 400 clamp(1.25rem, 3.5vw, 2.25rem) / 1.11111 var(--font-heading);
  --heading-copy: 400 clamp(1.5rem, 3vw, 1.75rem) / 1.42857 var(--font-heading);
  --heading-item: 600 clamp(1.25rem, 5vw, 1.5rem) / 1.33333 var(--font-heading);
  --heading-item-small: 600 clamp(1.125rem, 5vw, 1.375rem) / 1.45455 var(--font-heading);
  --text-large-teaser: 300 clamp(1.25rem, 3vw, 1.625rem) / 1.53846 var(--font-heading);
  --text-button: 600 clamp(1rem, 3vw, 1.25rem) / 1.2 var(--font-heading);
  --text-button-small: 600 clamp(1rem, 3vw, 1.125rem) / 1.33333 var(--font-heading);
  --text-copy: 300 clamp(1rem, 3vw, 1.375rem) / 1.81818 var(--font-copy);
  --text-quote: 300 clamp(1.25rem, 5vw, 2.5rem) / 1.2 var(--font-heading);
  --text-item: 300 italic clamp(1.125rem, 3vw, 1.25rem) / 1.6 var(--font-copy);
  --text-meta: 300 italic clamp(1rem, 3vw, 1.125rem) / 1.77778 var(--font-copy);
  --text-tiny: 300 italic 1.125rem / 1.5 var(--font-copy);
  --text-meta-small: 200 clamp(1rem, 2.5vw, 1.125rem) / 1.77778 var(--font-heading);
  --text-link: 600 clamp(1rem, 2vw, 1.25rem) / 2 var(--font-heading);
  --text-link-large: 600 clamp(1rem, 2.5vw, 1.375rem) / 1.45455 var(--font-heading);
  --text-menu: 1rem / 1.5 var(--font-heading);
  --text-menu-tiny: .875rem / 1.71429 var(--font-heading);
  --text-label: .6875rem / 2.18182 var(--font-heading);
  --shadow-stage: 1px 2px 2px #00000005, 2px 4px 4px #00000005, 4px 8px 8px #00000005, 8px 16px 16px #00000005, 16px 32px 32px #00000005;
  --shadow-stage-dark: 1px 2px 2px #00000012, 2px 4px 4px #00000012, 4px 8px 8px #00000012, 8px 16px 16px #00000012, 16px 32px 32px #00000012;
  --shadow-stage-ultra-dark: 1px 2px 2px #0000001a, 2px 4px 4px #0000001a, 4px 8px 8px #0000001a, 8px 16px 16px #0000001a, 16px 32px 32px #0000001a;
  --shadow-high-centered: 0 2px 2px #00000026, 0 4px 4px #00000026, 0 8px 8px #00000026, 0 16px 16px #00000026, 0 32px 32px #00000026;
  --shadow-button: 0px 1px 1px #0000001f, 0px 2px 2px #0000001f, 0px 4px 4px #0000001f, 0px 8px 8px #0000001f, 0px 16px 16px #0000001f;
  --shadow-button-hover: 0px 2px 1px #00000029, 0px 4px 2px #00000029, 0px 6px 4px #00000029, 0px 10px 8px #00000029, 0px 18px 16px #00000029;
  --gradient-charade: linear-gradient(212.08deg, #404754 10%, var(--color-charade) 89.12%);
  --gradient-highlight: linear-gradient(83.38deg, #2f3542 31.83%, #364352 76.47%), linear-gradient(83.38deg, #2f3542 31.83%, #2f3d4d 76.47%);
  --header-height: 4.5rem;
  --block-margin: 2.5rem;
}

@media only screen and (width >= 62em) {
  :root {
    --header-height: 5.5rem;
    --block-margin: 5rem;
  }
}

@media only screen and (width >= 90em) {
  :root {
    --header-height: 7.5rem;
  }
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 200;
  src: url("outfit-v11-latin-200.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: normal;
  src: url("outfit-v11-latin-regular.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: bold;
  src: url("outfit-v11-latin-600.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 300;
  src: url("source-sans-3-v15-latin-300.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Source Sans 3";
  font-style: italic;
  font-weight: 300;
  src: url("source-sans-3-v15-latin-300italic.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  src: url("source-sans-3-v15-latin-600.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Source Sans 3";
  font-style: italic;
  font-weight: 600;
  src: url("source-sans-3-v15-latin-600italic.woff2") format("woff2");
}

[class*="heading-"] {
  margin-top: 0;
  margin-bottom: var(--heading-margin, 1rem);
  text-wrap: balance;
}

[class*="heading-"] a {
  color: currentColor;
  text-decoration: none;
}

[class*="heading-"] a:hover {
  text-decoration: underline;
}

.heading-large-teaser {
  font: var(--heading-large-teaser);
  margin-bottom: 1.5rem;
}

.heading-title {
  color: var(--color-text-title);
  font: var(--heading-title);
}

.heading-subtitle {
  color: var(--color-text-subtitle);
  font: var(--heading-subtitle);
}

.heading-home-title {
  color: var(--color-text-title);
  font: var(--heading-title);
  text-align: center;
}

@media only screen and (width >= 62em) {
  .heading-home-title {
    text-align: left;
  }
}

.heading-home-subtitle {
  color: var(--color-text-subtitle);
  font: var(--heading-subtitle);
  text-align: center;
}

@media only screen and (width >= 62em) {
  .heading-home-subtitle {
    text-align: left;
  }
}

.heading-item {
  font: var(--heading-item);
}

.heading-item-small {
  --heading-margin: 2rem;
  font: var(--heading-item-small);
}

.heading-breadcrumb {
  color: var(--color-text-breadcrumb);
  font: var(--heading-breadcrumb);
  letter-spacing: .125em;
  text-transform: uppercase;
}

.heading-copy {
  color: var(--color-heading-copy);
  font: var(--heading-copy);
}

.heading-meta {
  font: var(--heading-meta);
}

.heading-center {
  text-align: center;
  max-width: 45rem;
  margin-left: auto;
  margin-right: auto;
}

.text-copy {
  font: var(--text-copy);
}

.text-copy strong {
  font-weight: 600;
}

.text-copy a:not([class]) {
  color: inherit;
  text-decoration: underline;
}

.text-copy a:not([class]):hover {
  text-decoration: none;
}

.text-copy p {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.text-copy p:first-child {
  margin-top: 0;
}

.text-copy h2, .text-copy h3, .text-copy h4 {
  font: var(--heading-copy);
  text-wrap: balance;
  margin-top: 0;
  margin-bottom: 1rem;
}

.text-copy ul, .text-copy ol {
  margin: 0;
  padding-left: 1.5rem;
}

.text-meta {
  font: var(--text-meta);
}

.text-meta strong {
  font-weight: 600;
}

.text-meta a {
  color: inherit;
  text-decoration: underline;
}

.text-meta a:hover {
  text-decoration: none;
}

.text-meta p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.text-meta ul, .text-meta ol {
  margin: 0;
  padding-left: 1.5rem;
}

.text-large-teaser {
  color: var(--color-text-subtitle);
  font: var(--text-large-teaser);
}

.text-large-teaser p {
  margin-top: 0;
  margin-bottom: 2rem;
}

.text-meta-small {
  font: var(--text-meta-small);
}

.text-meta-small strong {
  font-weight: 600;
}

.text-item {
  font: var(--text-item);
}

.text-center {
  text-align: center;
}

.text-normal {
  font-weight: 300;
}

.bg-canvas {
  background-color: var(--color-bg-canvas);
}

.bg-white {
  background-color: var(--color-white);
}

.color-invert {
  color: var(--color-text-invert);
}

.color-manatee {
  color: var(--color-manatee);
}

.gr-charade {
  background: var(--gradient-charade);
}

.gr-highlight {
  background: var(--gradient-highlight);
}

html {
  box-sizing: border-box;
  height: 100%;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  background-color: var(--color-bg-canvas);
  color: var(--color-text-primary);
  font-family: var(--font-copy);
  padding: 0;
  padding-top: var(--header-height);
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  line-height: 1.5;
  overflow-x: hidden;
}

svg {
  fill: currentColor;
  vertical-align: middle;
  width: var(--svg-width, 1rem);
  height: var(--svg-height, 1rem);
  display: inline-block;
  overflow: hidden;
}

img {
  aspect-ratio: var(--aspect-ratio);
  max-width: 100%;
  height: auto;
}

img:not([data-nobg]) {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWFlMWY3Ij48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=");
}

p {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.deco {
  --deco-border-width: .25rem;
  --deco-border-style: solid;
  --deco-border-color: var(--color-gallery);
  --deco-border: var(--deco-border-width) var(--deco-border-style) var(--deco-border-color);
  border-color: var(--color-gallery);
  width: 100%;
  display: inline-block;
}

.deco[data-deco="vert"] {
  border-left: var(--deco-border);
  width: 0;
  height: 12rem;
  max-height: 100%;
}

.deco[data-deco="service"] {
  display: none;
}

@media only screen and (width >= 62em) {
  .deco[data-deco="service"] {
    align-self: var(--deco-align-self);
    border-top: var(--deco-border);
    border-right: var(--deco-border);
    max-width: 16.75rem;
    height: 4.5rem;
    display: inline-block;
  }
}

.deco[data-deco="service"][class*="flip"] {
  margin-left: 6rem;
}

.deco[data-deco="reference"] {
  display: none;
}

@media only screen and (width >= 62em) {
  .deco[data-deco="reference"] {
    border-bottom: var(--deco-border);
    border-left: var(--deco-border);
    max-width: 11.5rem;
    height: 6rem;
    display: inline-block;
  }
}

.deco[data-deco="reference"]:not([class*="flip"]) {
  margin-left: 6rem;
}

.deco[data-deco="home-refs"] {
  display: none;
}

@media only screen and (width >= 62em) {
  .deco[data-deco="home-refs"] {
    align-self: var(--deco-align-self);
    border-left: var(--deco-border);
    border-bottom: var(--deco-border);
    max-width: 16.75rem;
    height: calc(100% - 7rem);
    max-height: 100%;
    margin-left: 3.5rem;
    display: inline-block;
  }
}

.deco[data-deco="page-title"]:after {
  border-left: var(--deco-border);
  content: "";
  height: 5rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: 6rem;
  display: block;
}

@media only screen and (width >= 62em) {
  .deco[data-deco="page-title"]:after {
    height: 12rem;
  }
}

.list-plain {
  margin-bottom: var(--block-margin);
  padding: 0;
  list-style: none;
}

.list-plain > li {
  margin-bottom: 1rem;
}

.list-items {
  gap: var(--list-gap, 1.5rem);
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin: 2.5rem 0 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media only screen and (width >= 48em) {
  .list-items {
    --list-gap: 2.5rem;
  }
}

.list-items > * {
  flex: 0 120px;
}

@media only screen and (width >= 48em) {
  .list-items > * {
    flex: 0 200px;
  }
}

.pt-box--s {
  padding-top: var(--padding-top, 1.5rem);
}

@media only screen and (width >= 62em) {
  .pt-box--s {
    --padding-top: 3.5rem;
  }
}

.pb-box--s {
  padding-bottom: var(--padding-bottom, 2.5rem);
}

@media only screen and (width >= 62em) {
  .pb-box--s {
    --padding-bottom: 4.5rem;
  }
}

.pt-box--l {
  padding-top: var(--padding-top, 2.5rem);
}

@media only screen and (width >= 62em) {
  .pt-box--l {
    --padding-top: 5rem;
  }
}

.pb-box--l {
  padding-bottom: var(--padding-bottom, 3.5rem);
}

@media only screen and (width >= 62em) {
  .pb-box--l {
    --padding-bottom: 6rem;
  }
}

.border-bottom {
  border-bottom: 1px solid var(--color-alto);
}

.img-round {
  border-radius: 50%;
}

.img-mask {
  border-radius: .1875rem;
  -webkit-mask-image: url("mask-pagehead.fdb48582.svg");
  mask-image: url("mask-pagehead.fdb48582.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.flip-hor {
  transform: scaleX(-1);
}

@media only screen and (width <= 47.9375em) {
  .show-for-xl {
    display: none !important;
  }
}

@media only screen and (width <= 61.9375em) {
  .show-for-xxl {
    display: none !important;
  }
}

.cards {
  --cards-col-repeat: 1;
  --cards-gap: 2.5rem;
  gap: var(--cards-gap);
  grid-template-columns: repeat(var(--cards-col-repeat), var(--cards-max-width, 1fr));
  margin-bottom: var(--block-margin);
  grid-auto-flow: row;
  justify-content: center;
  display: grid;
}

@media only screen and (width >= 35.75em) {
  .cards {
    --cards-max-width: .75fr;
  }
}

@media only screen and (width >= 48em) {
  .cards {
    --cards-col-repeat: 2;
    --cards-gap: 3.5rem 2.5rem;
    --cards-max-width: 1fr;
  }
}

@media only screen and (width >= 1200px) {
  .cards {
    --cards-col-repeat: 3;
    --cards-gap: 5rem 3rem;
  }
}

.columns {
  gap: var(--columns-gap, 1.5rem);
  max-width: var(--columns-max-width, 40rem);
  text-align: var(--columns-text-align, center);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

@media only screen and (width >= 62em) {
  .columns {
    --columns-gap: 4rem;
    --columns-text-align: left;
    --columns-max-width: none;
    align-items: top;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    display: grid;
  }
}

.site {
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  height: 100%;
  display: grid;
}

.grid {
  --grid-col-gap-default: 12px;
  --grid-col-gap-medium: 24px;
  --grid-col-gap-large: 24px;
  --grid-col-gap-xlarge: 24px;
  --grid-col-gap-xxlarge: 24px;
  --grid-row-gap-default: 12px;
  --grid-row-gap-medium: 24px;
  --grid-row-gap-large: 24px;
  --grid-row-gap-xlarge: 24px;
  --grid-row-gap-xxlarge: 24px;
  --grid-rows-default: auto;
  --grid-rows-medium: auto;
  --grid-rows-large: auto;
  --grid-rows-xlarge: auto;
  --grid-col-gap: var(--grid-col-gap-small, var(--grid-col-gap-default));
  --grid-row-gap: var(--grid-row-gap-small, var(--grid-row-gap-default));
  --grid-col-width: calc(calc(var(--grid-max, 87.5rem)  - calc(var(--grid-cols, 12) * var(--grid-col-gap, var(--grid-col-gap-default)))) / var(--grid-cols, 12));
  gap: var(--grid-row-gap) var(--grid-col-gap);
  grid-template-columns: [start] minmax(var(--grid-col-gap, 0), 1fr) [grid-start] repeat(var(--grid-cols, 12), minmax(0, var(--grid-col-width))) [grid-end] minmax(var(--grid-col-gap, 0), 1fr) [end];
  grid-template-rows: var(--grid-rows, var(--grid-rows-default));
  display: grid;
}

@media (width >= 572px) {
  .grid {
    --grid-col-gap: var(--grid-col-gap-medium, var(--grid-col-gap-small, var(--grid-col-gap-default)));
    --grid-row-gap: var(--grid-row-gap-medium, var(--grid-row-gap-small, var(--grid-row-gap-default)));
    --grid-rows: var(--grid-rows-medium, var(--grid-rows-small, var(--grid-rows-default)));
  }
}

@media (width >= 768px) {
  .grid {
    --grid-col-gap: var(--grid-col-gap-large, var(--grid-col-gap-medium, var(--grid-col-gap-small, var(--grid-col-gap-default))));
    --grid-row-gap: var(--grid-row-gap-large, var(--grid-row-gap-medium, var(--grid-row-gap-small, var(--grid-row-gap-default))));
    --grid-rows: var(--grid-rows-large, var(--grid-rows-medium, var(--grid-rows-small, var(--grid-rows-default))));
  }
}

@media (width >= 992px) {
  .grid {
    --grid-col-gap: var(--grid-col-gap-xlarge, var(--grid-col-gap-large, var(--grid-col-gap-medium, var(--grid-col-gap-small, var(--grid-col-gap-default)))));
    --grid-row-gap: var(--grid-row-gap-xlarge, var(--grid-row-gap-large, var(--grid-row-gap-medium, var(--grid-row-gap-small, var(--grid-row-gap-default)))));
    --grid-rows: var(--grid-rows-xlarge, var(--grid-rows-large, var(--grid-rows-medium, var(--grid-rows-small, var(--grid-rows-default)))));
  }
}

@media (width >= 1400px) {
  .grid {
    --grid-col-gap: var(--grid-col-gap-xxlarge, var(--grid-col-gap-xlarge, var(--grid-col-gap-large, var(--grid-col-gap-medium, var(--grid-col-gap-small, var(--grid-col-gap-default))))));
    --grid-row-gap: var(--grid-row-gap-xxlarge, var(--grid-row-gap-xlarge, var(--grid-row-gap-large, var(--grid-row-gap-medium, var(--grid-row-gap-small, var(--grid-row-gap-default))))));
    --grid-rows: var(--grid-rows-xxlarge, var(--grid-rows-xlarge, var(--grid-rows-large, var(--grid-rows-medium, var(--grid-rows-small, var(--grid-rows-default))))));
  }
}

.grid > * {
  --grid-area-default: 1 / grid-start / 1 / grid-end;
  --grid-area: var(--grid-area-small, var(--grid-area-default));
  align-self: var(--grid-align-self, stretch);
  grid-area: var(--grid-area);
  z-index: var(--grid-col-zindex);
  position: relative;
}

@media (width >= 572px) {
  .grid > * {
    --grid-area: var(--grid-area-medium, var(--grid-area-small, var(--grid-area-default)));
  }
}

@media (width >= 768px) {
  .grid > * {
    --grid-area: var(--grid-area-large, var(--grid-area-medium, var(--grid-area-small, var(--grid-area-default))));
  }
}

@media (width >= 992px) {
  .grid > * {
    --grid-area: var(--grid-area-xlarge, var(--grid-area-large, var(--grid-area-medium, var(--grid-area-small, var(--grid-area-default)))));
  }
}

@media (width >= 1400px) {
  .grid > * {
    --grid-area: var(--grid-area-xxlarge, var(--grid-area-xlarge, var(--grid-area-large, var(--grid-area-medium, var(--grid-area-small, var(--grid-area-default))))));
  }
}

.block {
  --gap: clamp(1.5rem, 6vw, 1.5rem);
  --full: minmax(var(--gap), 1fr);
  --content: min(86.5rem, 100% - var(--gap) * 2);
  --feature: minmax(0, 5rem);
  --narrow: minmax(0, 3rem);
  grid-template-columns: [full-start] var(--full) [feature-start] var(--feature) [content-start] var(--content) [content-end] var(--feature) [feature-end] var(--full) [full-end];
  background: var(--block-bg);
  color: var(--block-color);
  display: grid;
  position: relative;
}

@media only screen and (width >= 62em) {
  .block {
    --gap: clamp(48px, 6vw, 48px);
  }
}

.block > * {
  grid-column: content;
}

.block[data-layout="feature"] > * {
  grid-column: full;
}

@media only screen and (width >= 90em) {
  .block[data-layout="feature"] > * {
    grid-column: feature;
  }
}

.block[data-layout="full"] > * {
  grid-column: full;
}

.block[data-layout="narrow"] > * {
  justify-self: center;
  max-width: 56.25rem;
}

.block[data-width="full"] > * {
  width: 100%;
}

.block[data-spacer="large"] {
  margin-bottom: var(--block-margin);
}

.block[data-spacer="xlarge"] {
  margin-bottom: 4rem;
}

@media only screen and (width >= 48em) {
  .block[data-spacer="xlarge"] {
    margin-bottom: 9rem;
  }
}

.block-item {
  width: 100%;
  container: block / inline-size;
}

.block-item:not(.no-margin) {
  margin-bottom: var(--block-margin);
}

.editorial {
  --editorial-grid-areas: "content" "sidebar" "menu";
  --editorial-gap: 2.5rem 0;
  --editorial-grid-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-template-rows: var(--editorial-grid-rows);
  gap: var(--editorial-gap);
  grid-auto-rows: auto;
  grid-auto-flow: row;
  grid-template-areas: var(--editorial-grid-areas);
  margin-bottom: var(--block-margin);
  justify-content: start;
  place-items: start;
  display: grid;
}

@media only screen and (width >= 62em) {
  .editorial {
    --editorial-gap: 0 8rem;
    --editorial-grid-areas: "content menu" "content sidebar";
    --editorial-grid-rows: min-content 1fr;
    grid-template-columns: 1.3fr .7fr;
  }
}

.editorial:not(:has(.editorial__sidebar)) {
  --editorial-grid-areas: "content" "menu";
  --editorial-grid-rows: auto auto;
}

@media only screen and (width >= 62em) {
  .editorial:not(:has(.editorial__sidebar)) {
    --editorial-grid-areas: "content menu";
    --editorial-grid-rows: auto;
  }
}

.editorial:not(:has(.editorial__menu)) {
  --editorial-grid-areas: "content" "sidebar";
  --editorial-grid-rows: auto auto;
}

@media only screen and (width >= 62em) {
  .editorial:not(:has(.editorial__menu)) {
    --editorial-grid-areas: "content sidebar";
    --editorial-grid-rows: auto;
  }
}

.editorial:not(:has(.editorial__sidebar)):not(:has(.editorial__menu)) {
  --editorial-grid-areas: "content";
  --editorial-grid-rows: auto;
}

@media only screen and (width >= 62em) {
  .editorial[data-layout="switch"] {
    grid-template-columns: .7fr 1.3fr;
    grid-template-areas: "menu content"
                         "sidebar content";
  }

  .editorial[data-layout="switch"]:not(:has(.editorial__menu)) {
    grid-template-areas: "sidebar content";
  }
}

.editorial[data-type="mobile-switch"] .editorial__menu {
  grid-row: 1;
}

.editorial[data-type="mobile-switch"] .editorial__content {
  grid-row: 2;
}

@media only screen and (width >= 62em) {
  .editorial[data-type="mobile-switch"] .editorial__content {
    grid-row: 1;
  }
}

.editorial__content {
  grid-area: content;
  width: 100%;
}

.editorial__content p {
  max-width: 70ch;
}

.editorial__menu {
  grid-area: menu;
  width: 100%;
}

.editorial__sidebar {
  align-self: var(--editorial-align-self);
  grid-area: sidebar;
  width: 100%;
}

@media only screen and (width >= 62em) {
  .editorial__sidebar {
    height: var(--editorial-sidebar-height);
  }
}

.header {
  --header-shadow: var(--shadow-stage);
  --gap: clamp(1.5rem, 6vw, 1.5rem);
  --full: minmax(var(--gap), 1fr);
  --content: min(86.5rem, 100% - var(--gap) * 2);
  --feature: minmax(0, 5rem);
  --narrow: minmax(0, 3rem);
  grid-template-columns: minmax(var(--gap), 1fr) minmax(var(--gap), 86.5rem) minmax(var(--gap), 1fr);
  box-shadow: var(--header-shadow, none);
  height: var(--header-height);
  transition: var(--header-transition, none);
  z-index: 1;
  grid-template-areas: ". content .";
  align-items: center;
  width: 100%;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media only screen and (width >= 62em) {
  .header {
    --gap: clamp(48px, 6vw, 48px);
  }
}

.header > * {
  grid-area: content;
}

.header[data-layout="feature"] > * {
  grid-column: full;
}

@media only screen and (width >= 90em) {
  .header[data-layout="feature"] > * {
    grid-column: feature;
  }
}

.header[data-layout="full"] > * {
  grid-column: full;
}

.header[data-layout="narrow"] > * {
  justify-self: center;
  max-width: 56.25rem;
}

.header[data-width="full"] > * {
  width: 100%;
}

@media only screen and (width >= 62em) {
  .header {
    --header-grid-cols: 15.5rem 1fr;
    --header-transition: all .1s ease;
    --header-shadow: none;
    --navbar-font: var(--text-menu-tiny);
  }
}

@media only screen and (width >= 90em) {
  .header {
    --navbar-font: var(--text-menu);
  }
}

@media only screen and (width >= 62em) {
  .header[data-state="slim"] {
    --header-height: 4rem;
    --header-shadow: var(--shadow-stage);
    --navbar-font: var(--text-menu-tiny);
  }

  .header[data-state="slim"] [data-type="tiny"] {
    --button-font: var(--text-menu-tiny);
  }

  .header[data-state="slim"] .branding {
    --svg-width: 10.9375rem;
    --svg-height: 2rem;
  }
}

.footer {
  --grid-row-gap: 3rem;
  --footer-padding-top: 5rem;
  padding-top: var(--footer-padding-top);
  padding-bottom: var(--footer-padding-bottom, 4rem);
  -webkit-mask-image: url("mask-footer.8594e3e3.svg");
  mask-image: url("mask-footer.8594e3e3.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media only screen and (width >= 62em) {
  .footer {
    --footer-padding-top: 10rem;
    --footer-padding-bottom: 7.5rem;
  }
}

.footer .branding {
  --grid-area-xlarge: 1 / grid-start / 1 / 8;
}

.footer .foobar {
  --grid-area-small: 2 / grid-start / 2 / grid-end;
  --grid-area-xlarge: 1 / 8 / 1 / grid-end;
}

.contact + .footer {
  margin-top: calc(var(--footer-padding-top) * -1);
}

.linkgroup {
  text-align: center;
  font-size: 0;
}

.linkgroup:has(.button) {
  --button-margin: 0 1rem 1rem;
}

.linkgroup:not(:has(.button)) {
  --link-margin: 0 1rem 1rem;
}

.linklist {
  font-size: 0;
}

.linklist > * {
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.listicle {
  --listicle-areas: "content" "sidebar";
  --listicle-columns: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  grid-template-columns: var(--listicle-columns);
  grid-template-rows: 1fr;
  grid-template-areas: var(--listicle-areas);
  gap: 1.5rem;
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  display: grid;
}

@media only screen and (width >= 62em) {
  .listicle {
    --listicle-areas: "content sidebar";
    --listicle-columns: minmax(300px, 70%) minmax(300px, 30%);
  }
}

.listicle__content {
  grid-area: content;
}

.listicle__sidebar {
  grid-area: sidebar;
  justify-self: end;
}

.profile {
  --profile-cols: 1fr;
  --profile-body-col: 1fr;
  --profile-gap: 1.5rem;
  color: inherit;
  grid-auto-rows: auto;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  grid-template-columns: var(--profile-cols);
  gap: var(--profile-gap);
  grid-template-rows: 1fr;
  text-decoration: none;
  display: grid;
  position: relative;
  container: profile / inline-size;
}

.profile:has(a):hover .profile__img img {
  box-shadow: var(--shadow-stage);
  transform: translateY(-.075em);
}

@container block (width >= 29.375rem) {
  .profile {
    --profile-cols: minmax(200px, 240px) minmax(200px, 300px);
    --profile-gap: 2.5rem;
    --profile-text-align: left;
  }
}

@container block (width >= 48.75rem) {
  .profile {
    --profile-gap: 4rem;
  }
}

@container block (width >= 51.875rem) {
  .profile {
    --profile-cols: max-content minmax(200px, 530px);
  }
}

.profile__img {
  max-width: var(--profile-img-max, 16.25rem);
  justify-self: center;
}

@media only screen and (width >= 48em) {
  .profile__img {
    --profile-img-max: 21.25rem;
  }
}

.profile__img img {
  border: 8px solid #fff;
  transition: all 75ms;
  display: block;
}

.profile__content {
  text-align: var(--profile-text-align, center);
  align-self: center;
}

.teaser {
  gap: var(--teaser-gap, 1.5rem);
  max-width: var(--teaser-max-width, 40rem);
  text-align: var(--teaser-text-align, center);
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

@media only screen and (width >= 62em) {
  .teaser {
    --teaser-gap: 4rem;
    --teaser-text-align: left;
    --teaser-max-width: none;
    grid-template-rows: 1fr;
    grid-template-columns: .75fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    align-items: center;
    display: grid;
  }
}

@media only screen and (width >= 90em) {
  .teaser {
    --teaser-gap: 6.5rem;
  }
}

.teaser .teaser__img {
  align-self: self-start;
}

.accordion {
  --accordion-padding: 1rem;
  --accordion-arrow: url("icon-arrow-dark.9ea6190f.svg");
  --accordion-arrow-size: 2.5rem;
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
  box-shadow: var(--shadow-stage);
  margin-bottom: 1rem;
  overflow: hidden;
}

@media only screen and (width >= 48em) {
  .accordion {
    --accordion-padding: 2rem;
    --accordion-arrow-offset: 5rem;
    --accordion-arrow-right: 2rem;
  }
}

.accordion [aria-expanded="true"] {
  --accordion-title-bg: var(--color-shuttle);
  --accordion-title-color: var(--color-alabaster);
  --accordion-arrow: url("icon-arrow-light.d1ccf12e.svg");
  --accordion-arrow-rotate: 180deg;
}

.accordion:focus-within .accordion__trigger {
  background-color: var(--accordion-title-bg);
}

[class*="heading-"] + .accordion {
  margin-top: 2rem;
}

.accordion__title {
  margin-bottom: 0;
}

.accordion__trigger {
  background: var(--accordion-title-bg, none);
  color: var(--accordion-title-color);
  cursor: pointer;
  font: var(--heading-item);
  font-size: inherit;
  padding: var(--accordion-padding);
  padding-right: var(--accordion-arrow-offset, 3rem);
  text-align: left;
  border: none;
  outline: none;
  width: 100%;
  position: relative;
}

.accordion__trigger:after {
  background: var(--accordion-arrow);
  content: "";
  right: var(--accordion-arrow-right, 1rem);
  transform: rotate(var(--accordion-arrow-rotate, 0deg));
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: block;
  position: absolute;
  top: calc(50% - 1rem);
}

@media only screen and (width >= 48em) {
  .accordion__trigger:after {
    width: 2.5rem;
    height: 2.5rem;
    top: calc(50% - 1.25rem);
  }
}

.accordion__trigger:focus, .accordion__trigger:hover {
  --accordion-title-bg: var(--color-oxford);
  --accordion-title-color: var(--color-alabaster);
  --accordion-arrow: url("icon-arrow-light.d1ccf12e.svg");
}

.accordion__body {
  padding: var(--accordion-padding);
}

.accordion__body p:last-child {
  margin-bottom: 0;
}

.branding {
  --svg-width: 9.125rem;
  --svg-height: 1.5rem;
  width: 100%;
}

.branding a {
  color: var(--color-text-primary);
  display: inline-block;
}

.branding svg {
  transition: all .1s;
}

@media only screen and (width >= 62em) {
  .branding {
    --svg-width: 10.9375rem;
    --svg-height: 2rem;
  }
}

@media only screen and (width >= 90em) {
  .branding {
    --svg-width: 15.5rem;
    --svg-height: 2.5rem;
  }
}

.button {
  --button-shadow: var(--shadow-button);
  --button-font: var(--text-button);
  background: var(--button-bg);
  border-radius: var(--button-border-radius, 2rem);
  color: var(--button-color, var(--color-text-primary));
  box-shadow: var(--button-shadow);
  font: var(--button-font);
  letter-spacing: var(--button-spacing, .01875em);
  margin: var(--button-margin);
  padding: var(--button-padding, 1.3125rem 2.8125rem);
  text-shadow: var(--button-text-shadow, 0px 1px 1px #ffffffab);
  text-decoration: none;
  transition: all 75ms;
  display: inline-block;
}

.button:not([disabled]):hover {
  --button-shadow: var(--shadow-button-hover);
  filter: contrast(130%);
  transform: translateY(-.12em);
}

.button[data-style="primary"] {
  --button-bg: linear-gradient(258.11deg, #7bdfed 31.07%, #9ae1f7 46.46%, #9cd5f6 61.9%);
}

.button[data-type="small"] {
  --button-font: var(--text-button-small);
  --button-padding: 1rem 3rem;
  --button-color: var(--color-alabaster);
  --button-bg: linear-gradient(187.68deg, #525a6a 2.84%, #2f3542 92.56%);
  --button-text-shadow: none;
}

.button[data-type="tiny"] {
  --button-border-radius: .25rem;
  --button-font: var(--text-menu);
  --button-shadow: var(--shadow-stage);
  --button-padding: .5rem 1.5rem;
  --button-spacing: .09375rem;
  text-transform: uppercase;
}

.button[data-type="tiny"]:hover {
  --button-shadow: var(--shadow-stage-dark);
}

.button[disabled] {
  cursor: not-allowed;
  opacity: .5;
}

.button-ghost {
  cursor: pointer;
  margin: var(--button-margin);
  background: none;
  border: none;
  padding: 0;
}

.card {
  --card-padding: 2rem 1.5rem;
  --card-heading: var(--heading-item);
  box-shadow: var(--card-shadow, var(--shadow-stage));
  border-radius: .375rem;
  grid-template-rows: auto auto 1fr;
  transition: all 50ms;
  display: grid;
  position: relative;
  overflow: hidden;
  container: card / inline-size;
}

.card:not([data-style="highlight"]):hover {
  --card-shadow: var(--shadow-stage-dark);
  transform: translateY(-.15em);
}

.card[data-style="highlight"] {
  --card-shadow: var(--shadow-stage-dark);
  --link-color: var(--color-white);
}

.card__image {
  aspect-ratio: var(--aspect-ratio);
  background: linear-gradient(237.23deg, #767d89 49.5%, #4e545e 96.35%);
  place-items: center;
  display: grid;
}

.card__image img {
  max-width: var(--max-size, 100%);
  max-height: var(--max-size, 100%);
  display: block;
}

.card__label {
  margin-top: var(--card-label-pull, -.75em);
  min-height: var(--card-label-minheight, 1.5rem);
  justify-content: flex-end;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.card__content {
  padding: var(--card-padding);
  grid-template-rows: auto 1fr auto;
  display: grid;
}

.card__heading {
  font: var(--card-heading);
  margin-top: 0;
  margin-bottom: .5rem;
}

.card__body {
  font: var(--text-item);
  text-wrap: balance;
  margin-bottom: 1.5rem;
}

@container card (width <= 23.125rem) {
  .card__label {
    --card-label-pull: -.75em;
    --card-label-minheight: 1.5rem;
  }

  .card__content {
    --card-padding: 1.5rem 1rem;
  }

  .card__heading {
    --card-heading: var(--heading-item-small);
  }

  .card__body {
    --text-item: var(--text-meta);
  }
}

.contact {
  background: var(--gradient-highlight);
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: var(--contact-top-padding, 4rem);
  padding-bottom: var(--contact-bottom-padding, 6rem);
  text-align: center;
}

.contact[data-block="footer"] {
  --contact-bottom-padding: 8rem;
}

@media only screen and (width >= 62em) {
  .contact[data-block="footer"] {
    --contact-top-padding: 4rem;
    --contact-bottom-padding: 12rem;
  }
}

.contact[data-block="boxed"] {
  --contact-bottom-padding: 4.5rem;
  box-shadow: var(--shadow-stage-dark);
  margin-bottom: var(--block-margin);
  border-radius: .375rem;
}

@media only screen and (width >= 62em) {
  .contact[data-block="boxed"] {
    --contact-bottom-padding: 6rem;
  }
}

.contact__body {
  font: var(--text-copy);
  max-width: 65ch;
  margin: 0 auto;
}

.contact__body p {
  margin-top: 0;
  margin-bottom: 2.5rem;
}

.flyer {
  --flyer-padding: 4rem 6rem 6rem 4rem;
  --flyer-icon-color: var(--color-polar);
  --flyer-icon-size: 2rem;
  background-color: var(--flyer-bg, var(--color-white));
  box-shadow: var(--flyer-shadow, var(--shadow-stage));
  padding: var(--flyer-padding);
  border-radius: .375rem;
  transition: all 75ms;
  position: relative;
}

@media only screen and (width >= 90em) {
  .flyer {
    --flyer-icon-size: 3.5rem;
  }
}

.flyer svg {
  fill: var(--flyer-icon-color);
  width: var(--flyer-icon-size);
  height: var(--flyer-icon-size);
  margin-bottom: 1.5rem;
}

.flyer[data-style="highlight"] {
  --flyer-shadow: var(--shadow-stage-dark);
  --flyer-icon-color: var(--color-shuttle);
  --link-color: var(--color-white);
}

.flyer[data-style="highlight"]:has(.link:hover) {
  --flyer-shadow: var(--shadow-stage-ultra-dark);
}

.flyer:has(.link:hover) {
  --flyer-shadow: var(--shadow-stage-dark);
  transform: translateY(-.15em);
}

@container block (width <= 43.75rem) {
  .flyer {
    --flyer-padding: 1.5rem 1.5rem 2.5rem;
  }
}

.highlight {
  background-color: var(--highlight-bg, var(--color-white));
  border-left: .5rem solid var(--highlight-border, var(--color-polar));
  border-radius: .375rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 2rem 2.5rem;
}

.highlight p, .highlight ul, .highlight ol {
  max-width: 70ch;
}

.highlight p:last-child, .highlight ul:last-child, .highlight ol:last-child {
  margin-bottom: 0;
}

.highlight[data-style="important"] {
  --highlight-bg: var(--color-polar);
  --highlight-border: var(--color-charlotte);
}

.iconlink {
  --iconlink-icon: url("icon-social-website.cf4f2c58.svg");
  color: currentColor;
  padding-left: 2em;
  font-size: 1em;
  display: inline-block;
  position: relative;
}

.iconlink:hover {
  text-decoration: none;
}

.iconlink:before {
  background: var(--iconlink-icon);
  content: "";
  background-size: 100% 100%;
  width: 1.5em;
  height: 1.5em;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.iconlink[href*="linkedin.com"] {
  --iconlink-icon: url("icon-social-linkedin.9f681515.svg");
}

.iconlink[href*="xing.com"] {
  --iconlink-icon: url("icon-social-xing.28e8113d.svg");
}

.iconlink[href*="mailto:"] {
  --iconlink-icon: url("icon-mail.6be8a47c.svg");
}

.label {
  --label-bg: var(--color-mercury);
  --label-color: var(--color-text-primary);
  background-color: var(--label-bg);
  color: var(--label-color);
  font: var(--text-label);
  letter-spacing: .1875em;
  text-transform: uppercase;
  border-radius: .25rem;
  padding: 0 .625rem;
  display: inline-block;
}

a.label {
  text-decoration: none;
}

a.label:hover {
  background-color: var(--color-highlight);
}

.link {
  color: var(--link-color, var(--color-text-primary));
  cursor: pointer;
  font: var(--text-link);
  margin: var(--link-margin);
  background: none;
  background-image: var(--link-border, var(--gradient-border));
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 4px;
  border: 0;
  padding-bottom: .5rem;
  text-decoration: none;
}

.link:not([data-state="active"]):hover {
  --link-border: linear-gradient(var(--color-polar), var(--color-polar));
}

.link[data-state="active"] {
  --link-border: linear-gradient(currentColor, currentColor);
}

.link[data-state="inactive"] {
  --link-border: linear-gradient(var(--color-polar), var(--color-polar));
}

.link[data-state="inactive"]:hover {
  --link-border: var(--gradient-border);
}

.link[data-size="large"] {
  font: var(--text-link-large);
}

.link[data-type="full"]:before {
  content: "";
  z-index: 1;
  display: block;
  position: absolute;
  inset: 0;
}

.listle {
  margin: 0;
  padding-left: 3.5rem;
  position: relative;
}

.listle:before {
  content: "";
  background-image: url("icon-list-polar.b2c19058.svg");
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.listle dd {
  max-width: 70ch;
  margin: 0;
}

.logos {
  max-width: var(--logos-max-width, 81.25rem);
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 2.5rem;
  padding-bottom: 3rem;
}

@media only screen and (width >= 62em) {
  .logos {
    padding: 5rem 1.5rem 5.5rem;
  }
}

.menu {
  --menu-text-color: var(--color-shuttle);
  --svg-width: 1.9375rem;
  --svg-height: .875rem;
  color: var(--menu-text-color);
  cursor: pointer;
  letter-spacing: .125rem;
  text-transform: uppercase;
  background: none;
  border: none;
  justify-self: end;
  padding: 0;
  font-size: .5rem;
}

.menu p {
  margin: .375rem 0 0;
  line-height: .5rem;
}

@media only screen and (width >= 62em) {
  .menu {
    visibility: hidden;
    width: 0;
    display: none;
  }
}

.navbar {
  --navbar-background: var(--gradient-dark);
  --navbar-padding: 2rem 1.5rem 1rem;
  --navbar-item-color: var(--color-alabaster);
  --navbar-item-display: block;
  --navbar-item-margin: 2rem 0;
  background: var(--navbar-background);
  box-shadow: var(--shadow-high-centered);
  visibility: hidden;
  padding: var(--navbar-padding);
  text-align: right;
  width: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.navbar[data-state="open"] {
  visibility: visible;
  width: 100%;
  display: block;
}

@media only screen and (width >= 62em) {
  .navbar {
    --navbar-background: var(--color-bg-canvas);
    --navbar-padding: 0;
    --navbar-item-color: var(--color-text-primary);
    --navbar-item-display: inline-block;
    --navbar-item-margin: 0 1rem;
    box-shadow: none;
    padding: var(--navbar-padding);
    visibility: visible;
    justify-self: end;
    width: auto;
    display: block;
    position: relative;
  }
}

@media only screen and (width >= 90em) {
  .navbar {
    --navbar-item-margin: 0 1.5rem;
  }
}

.navbar .button {
  margin-bottom: 1rem;
  margin-right: 100%;
}

@media only screen and (width >= 62em) {
  .navbar .button {
    margin: 0 0 0 1rem;
  }
}

@media only screen and (width >= 90em) {
  .navbar .button {
    margin-left: 1.5rem;
  }
}

.navbar__item {
  color: var(--navbar-item-color);
  display: var(--navbar-item-display);
  font: var(--navbar-font, var(--text-menu));
  letter-spacing: .09375rem;
  margin: var(--navbar-item-margin);
  text-align: left;
  text-transform: uppercase;
  width: fit-content;
  text-decoration: none;
  position: relative;
}

.navbar__item:hover:after, .navbar__item[data-state="active"]:after {
  background: var(--gradient-border);
  content: "";
  width: 100%;
  height: .1875rem;
  display: block;
  position: absolute;
  bottom: -.5rem;
  left: 0;
}

.navbar__item:last-child {
  margin-right: 0;
}

.navbar__close {
  border-radius: var(--border-radius-l);
  color: var(--color-alabaster);
  background: #fff3;
  border: none;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

@media only screen and (width >= 62em) {
  .navbar__close {
    visibility: hidden;
    width: 0;
    display: none;
  }
}

.navbar__close:focus {
  border: 1px solid var(--color-spray);
}

.navbar__close .c-icon {
  width: 2em;
  height: 2em;
}

.pagehead {
  margin-bottom: var(--block-margin);
}

.pagehead[data-type="image"] .pagehead__mask {
  --pagehead-padding-top: 0;
  --pagehead-padding-bottom: 0;
  --pagehead-minheight: 0;
}

.pagehead figure {
  margin: 0;
}

.pagehead figcaption {
  color: var(--color-manatee);
  font: var(--text-tiny);
  text-align: right;
  margin-top: 1rem;
  padding-right: 1.5rem;
}

.pagehead__mask {
  aspect-ratio: var(--pagehead-aspect-ratio);
  background-color: var(--color-geyser);
  min-height: var(--pagehead-minheight, 10rem);
  padding-top: var(--pagehead-padding-top, 4rem);
  padding-bottom: var(--pagehead-padding-bottom, 2rem);
  border-radius: .1875rem;
  align-items: end;
  display: grid;
  -webkit-mask-image: url("mask-pagehead.fdb48582.svg");
  mask-image: url("mask-pagehead.fdb48582.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media only screen and (width >= 62em) {
  .pagehead__mask {
    --pagehead-padding-top: 5rem;
    --pagehead-padding-bottom: 6rem;
    --pagehead-minheight: 25rem;
  }
}

.pagehead__mask img {
  width: 100%;
  height: auto;
}

.pagetitle {
  max-width: var(--pagetitle-max-width, 100ch);
}

.submenu {
  padding-top: 1rem;
}

.submenu a:hover {
  text-decoration: underline;
}

.submenu__item {
  color: var(--color-text-primary);
  margin-bottom: 1.5rem;
  padding-left: 2.5rem;
  text-decoration: none;
  display: block;
  position: relative;
}

.submenu__item svg {
  fill: var(--submenu-icon, var(--color-alto));
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: .275em;
  left: 0;
}

.submenu__item[data-state="active"] {
  --submenu-icon: var(--color-polar);
  font-weight: bold;
}

.foobar {
  width: 100%;
  container-type: inline-size;
}

@container (width >= 500px) {
  .foobar__menu {
    columns: 2 auto;
    column-gap: 1rem;
  }
}

.foobar__item {
  color: var(--color-text-invert);
  font: var(--text-menu);
  letter-spacing: .09375rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-decoration: none;
  display: block;
}

.quote {
  margin: var(--quote-margin, 2rem) auto;
  text-align: center;
  max-width: 56.25rem;
}

@media only screen and (width >= 48em) {
  .quote {
    --quote-margin: 5rem;
  }
}

.quote p {
  font: var(--text-quote);
  text-wrap: balance;
  margin: 0;
}

.quote footer {
  color: var(--color-shuttle);
  font: var(--heading-item);
  margin-top: 1rem;
}

.quote footer:before {
  content: "— ";
}
/*# sourceMappingURL=app.css.map */

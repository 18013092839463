.button {
  --button-shadow: var(--shadow-button);
  --button-font: var(--text-button);
  background: var(--button-bg);
  border-radius: var(--button-border-radius, #{rem-baseline(4)});
  color: var(--button-color, var(--color-text-primary));
  box-shadow: var(--button-shadow);
  display: inline-block;
  font: var(--button-font);
  letter-spacing: var(--button-spacing, .01875em);
  margin: var(--button-margin);
  padding: var(--button-padding, #{rem-calc(21) rem-calc(45)});
  transition: all .075s ease;
  text-decoration: none;
  text-shadow: var(--button-text-shadow, 0px 1px 1px rgba(255, 255, 255, 0.668852));

  &:not([disabled]):hover {
    --button-shadow: var(--shadow-button-hover);
    transform: translateY(-.12em);
    filter: contrast(130%);
  }

  &[data-style='primary'] {
    --button-bg: linear-gradient(258.11deg, #7BDFED 31.07%, #9AE1F7 46.46%, #9CD5F6 61.9%);
  }

  &[data-type='small'] {
    --button-font: var(--text-button-small);
    --button-padding: #{rem-calc(16) rem-baseline(6)};
    --button-color: var(--color-alabaster);
    --button-bg: linear-gradient(187.68deg, #525A6A 2.84%, #2F3542 92.56%);
    --button-text-shadow: none;
  }

  &[data-type='tiny'] {
    --button-border-radius: #{rem-calc(4)};
    --button-font: var(--text-menu);
    --button-shadow: var(--shadow-stage);
    --button-padding: #{rem-baseline(1) rem-baseline(3)};
    --button-spacing: #{rem-calc(1.5)};
    text-transform: uppercase;

    &:hover {
      --button-shadow: var(--shadow-stage-dark);
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
  }
}

.button-ghost {
  background: none;
  border: none;
  cursor: pointer;
  margin: var(--button-margin);
  padding: 0;
}

.listle {
  @include u-baseline(7, padding-left);
  margin: 0;
  position: relative;

  &:before {
    @include u-baseline(4, width);
    @include u-baseline(4, height);
    content: '';
    background-image: url(/src/svg/icon-list-polar.svg);;
    background-size: 100% 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  dd {
    margin: 0;
    max-width: 70ch;
  }
}

.teaser {
  display: grid;
  gap: var(--teaser-gap, #{rem-baseline(3)});
  margin-left: auto;
  margin-right: auto;
  max-width: var(--teaser-max-width, #{rem-calc(640)});
  text-align: var(--teaser-text-align, center);

  @include breakpoint(xlarge) {
    --teaser-gap: #{rem-baseline(8)};
    --teaser-text-align: left;
    --teaser-max-width: none;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-template-columns: .75fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
  }

  @include breakpoint(xxlarge) {
    --teaser-gap: #{rem-baseline(13)};
  }

  .teaser__img {
    align-self: self-start;
  }
}

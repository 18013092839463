/** ***********
 * Site header including navbar
 */
.header {
  --header-shadow: var(--shadow-stage);
  @include composition-block($mixin: 1);
  align-items: center;
  box-shadow: var(--header-shadow, none);
  height: var(--header-height);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  transition: var(--header-transition, none);
  width: 100%;
  z-index: 1;

  @include breakpoint($navbar-breakpoint) {
    --header-grid-cols: #{rem-calc(248)} 1fr;
    --header-transition: all .1s ease;
    --header-shadow: none;
    --navbar-font: var(--text-menu-tiny);
  }

  @include breakpoint(xxlarge) {
    --navbar-font: var(--text-menu);
  }

  &[data-state='slim'] {
    @include breakpoint($navbar-breakpoint) {
      --header-height: #{rem-baseline(8)};
      --header-shadow: var(--shadow-stage);
      --navbar-font: var(--text-menu-tiny);

      [data-type='tiny'] {
        --button-font: var(--text-menu-tiny);
      }

      .branding {
        --svg-width: #{rem-calc(175)};
        --svg-height: #{rem-baseline(4)};
      }
    }
  }
}

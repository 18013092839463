.contact {
  @include u-baseline(5, padding-left);
  @include u-baseline(5, padding-right);
  background: var(--gradient-highlight);
  padding-top: var(--contact-top-padding, #{rem-baseline(8)});
  padding-bottom: var(--contact-bottom-padding, #{rem-baseline(12)});
  text-align: center;

  &[data-block='footer'] {
    --contact-bottom-padding: #{rem-baseline(16)};

    @include breakpoint($navbar-breakpoint) {
      --contact-top-padding: #{rem-baseline(8)};
      --contact-bottom-padding: #{rem-baseline(24)};
    }
  }

  &[data-block="boxed"] {
    --contact-bottom-padding: #{rem-baseline(9)};
    border-radius: rem-calc(6);
    box-shadow: var(--shadow-stage-dark);
    margin-bottom: var(--block-margin);

    @include breakpoint($navbar-breakpoint) {
      --contact-bottom-padding: #{rem-baseline(12)};
    }
  }
}

.contact__body {
  font: var(--text-copy);
  margin: 0 auto;
  max-width: 65ch;

  p {
    @include u-baseline(5, margin-bottom);
    margin-top: 0;
  }
}

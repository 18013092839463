.cards {
  --cards-col-repeat: 1;
  --cards-gap: #{rem-baseline(5)};
  display: grid;
  gap: var(--cards-gap);
  grid-template-columns: repeat(var(--cards-col-repeat), var(--cards-max-width, 1fr));
  grid-auto-flow: row;
  margin-bottom: var(--block-margin);
  justify-content: center;

  @include breakpoint(medium) {
    --cards-max-width: 0.75fr;
  }

  @include breakpoint(large) {
    --cards-col-repeat: 2;
    --cards-gap: #{rem-baseline(7)} #{rem-baseline(5)};
    --cards-max-width: 1fr;
  }

  // @include breakpoint(xlarge) {
  @media only screen and (width >= 1200px) {
    --cards-col-repeat: 3;
    --cards-gap: #{rem-baseline(10)} #{rem-baseline(6)};
  }
}

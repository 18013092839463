.deco {
  --deco-border-width: #{rem-calc(4)};
  --deco-border-style: solid;
  --deco-border-color: var(--color-gallery);
  --deco-border: var(--deco-border-width) var(--deco-border-style) var(--deco-border-color);
  border-color: var(--color-gallery);
  display: inline-block;
  width: 100%;

  &[data-deco='vert'] {
    @include u-baseline(24, height);
    border-left: var(--deco-border);
    max-height: 100%;
    width: 0;
  }

  &[data-deco='service'] {
    display: none;

    @include breakpoint(xlarge) {
      @include u-baseline(9, height);
      align-self: var(--deco-align-self);
      border-top: var(--deco-border);
      border-right: var(--deco-border);
      display: inline-block;
      max-width: rem-calc(268);
    }

    &[class*='flip'] {
      @include u-baseline(12, margin-left);
    }
  }

  &[data-deco='reference'] {
    display: none;

    @include breakpoint(xlarge) {
      @include u-baseline(12, height);
      border-bottom: var(--deco-border);
      border-left: var(--deco-border);
      display: inline-block;
      max-width: rem-calc(184);
    }

    &:not([class*='flip']) {
      @include u-baseline(12, margin-left);
    }
  }

  &[data-deco='home-refs'] {
    display: none;

    @include breakpoint(xlarge) {
      // @include u-baseline(38, height);
      @include u-baseline(7, margin-left);
      align-self: var(--deco-align-self);
      border-left: var(--deco-border);
      border-bottom: var(--deco-border);
      display: inline-block;
      max-height: 100%;
      max-width: rem-calc(268);
      height: calc(100% - #{rem-baseline(14)});
    }
  }

  &[data-deco='page-title'] {
    &:after {
      @include u-baseline(10, height);
      @include u-baseline(12, margin-left);
      @include u-baseline(10, margin-top);
      @include u-baseline(10, margin-bottom);
      border-left: var(--deco-border);
      content: '';
      display: block;

      @include breakpoint($navbar-breakpoint) {
        @include u-baseline(24, height);
      }
    }
  }
}

$accordion-arrow-size-small: rem-baseline(4);
$accordion-arrow-size-large: rem-baseline(5);

.accordion {
  --accordion-padding: #{rem-baseline(2)};
  --accordion-arrow: url(/src/svg/icon-arrow-dark.svg);
  --accordion-arrow-size: #{rem-baseline(5)};
  @include u-baseline(2, margin-bottom);
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
  box-shadow: var(--shadow-stage);
  overflow: hidden;

  @include breakpoint(large) {
    --accordion-padding: #{rem-baseline(4)};
    --accordion-arrow-offset: #{rem-baseline(10)};
    --accordion-arrow-right: #{rem-baseline(4)};
  }

  [aria-expanded='true'] {
    --accordion-title-bg: var(--color-shuttle);
    --accordion-title-color: var(--color-alabaster);
    --accordion-arrow: url(/src/svg/icon-arrow-light.svg);
    --accordion-arrow-rotate: 180deg;
  }

  &:focus-within .accordion__trigger {
    background-color: var(--accordion-title-bg);
  }
}

[class*='heading-'] + .accordion {
  @include u-baseline(4, margin-top);
}

.accordion__title {
  margin-bottom: 0;
}

.accordion__trigger {
  background: var(--accordion-title-bg, none);
  border: none;
  color: var(--accordion-title-color);
  cursor: pointer;
  font: var(--heading-item);
  font-size: inherit;
  outline: none;
  padding: var(--accordion-padding);
  padding-right: var(--accordion-arrow-offset, #{rem-baseline(6)});
  position: relative;
  text-align: left;
  width: 100%;

  &:after {
    background: var(--accordion-arrow);
    background-size: 100% 100%;
    content: '';
    display: block;
    width: $accordion-arrow-size-small;
    height: $accordion-arrow-size-small;
    position: absolute;
    right: var(--accordion-arrow-right, #{rem-baseline(2)});
    top: calc(50% - #{$accordion-arrow-size-small / 2});
    transform: rotate(var(--accordion-arrow-rotate, 0deg));

    @include breakpoint(large) {
      width: $accordion-arrow-size-large;
      height: $accordion-arrow-size-large;
      top: calc(50% - #{$accordion-arrow-size-large / 2});
    }
  }

  &:focus,
  &:hover {
    --accordion-title-bg: var(--color-oxford);
    --accordion-title-color: var(--color-alabaster);
    --accordion-arrow: url(/src/svg/icon-arrow-light.svg);
  }
}


.accordion__body {
  padding: var(--accordion-padding);

  p:last-child {
    margin-bottom: 0;
  }
}

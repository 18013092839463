.submenu {
  @include u-baseline(2, padding-top);

  a:hover {
    text-decoration: underline;
  }
}

.submenu__item {
  @include u-baseline(5, padding-left);
  @include u-baseline(3, margin-bottom);
  color: var(--color-text-primary);
  display: block;
  position: relative;
  text-decoration: none;

  svg {
    @include u-baseline(3, width);
    @include u-baseline(3, height);
    fill: var(--submenu-icon, var(--color-alto));
    position: absolute;
    left: 0;
    top: .275em;
  }

  &[data-state='active'] {
    --submenu-icon: var(--color-polar);
    font-weight: bold;
  }
}

.logos {
  @include u-baseline(5, padding-top);
  @include u-baseline(6, padding-bottom);
  margin: 0 auto;
  max-width: var(--logos-max-width, #{rem-calc(1300)});
  text-align: center;
  width: 100%;

  @include breakpoint($navbar-breakpoint) {
    @include u-baseline(3, padding-left);
    @include u-baseline(3, padding-right);
    @include u-baseline(10, padding-top);
    @include u-baseline(11, padding-bottom);
  }
}
